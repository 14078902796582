import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function App({ departmentDetail, setDepartmentDetail }: any) {
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(() => {
    const initialLat = parseFloat(departmentDetail?.lat);
    const initialLng = parseFloat(departmentDetail?.lng);

    if (!isNaN(initialLat) && !isNaN(initialLng)) {
      return { lat: initialLat, lng: initialLng };
    }

    return null;
  });

  const handleMapClick = (event: any) => {
    console.log(event.latLng.lat());
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();
    // console.log(`Latitude: ${newLat}, Longitude: ${newLng}`);
    setMarkerPosition({ lat: newLat, lng: newLng });
  };

  useEffect(() => {
    setDepartmentDetail((prev: any) => ({
      ...prev,
      geo_lat: markerPosition?.lat.toString(),
      geo_lng: markerPosition?.lng.toString(),
    }));
  }, [markerPosition]);

  const containerStyle = {
    width: "100%",
    height: "100vh",
  };
  const center = {
    lat: 25.2048,
    lng: 55.2708,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCGGe6k_mV6UO4lnptv3K2hxfcqM9gOz-0">
      <GoogleMap
        onClick={handleMapClick}
        mapContainerStyle={containerStyle}
        center={markerPosition || center}
        zoom={8}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </LoadScript>
  );
}

export default App;
