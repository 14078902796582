import { Col, Row } from "react-bootstrap";
import tumer from "../../assets/images/Tumer.svg";
import minus from "../../assets/images/minus.png";
import plant from "../../assets/images/plant.png";
import plus from "../../assets/images/plus.png";
import "./style.css";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import { RequestedPlantsCard } from "../RequestedPlantsCard";

const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  console.log(date, dateString);
};

const RequestedPlants: React.FC<{ width: number }> = ({ width })=> {
  console.log("request plant width",width);
  
  return (
    // <div style={{ width: width >= 35 ? "100%" : `100%` }}>
    <div>
      <Row className="requested_plants_container">
        <p className="requestPlants">Requested plants</p>

        <RequestedPlantsCard width={width} />
        <RequestedPlantsCard width={width} />
        <RequestedPlantsCard width={width} />
        <Col>
          <div className="totalDiv">
            <p className="SubTotal">Total</p>
            <p className="TotalPlantCount">24</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col >
          <div className="ml-4">
          <p className="requestPlants">Deliverable Date</p>
          <DatePicker  onChange={onChange} style={{marginLeft:'10PX'}} />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default RequestedPlants;
