import { Col } from "react-bootstrap";
import menubar from "../../assets/images/menuBar.png";
const EmployeeNote = () => {
  return (
    <Col style={{ paddingLeft: "60px", paddingRight: "60px" }}>
      <div
        style={{
          color: "#3E6E4B",
          fontSize: 16,
          fontFamily: "Alexandria",
          fontWeight: "700",
          wordWrap: "break-word",
        }}
      >
        Employee notes
      </div>
      <div style={{ border: "none", borderRadius: "8px 8px 8px 8px" }}>
        <div style={{ width: "100%" }}>
          <img
            src={menubar}
            alt="MenuBar"
            style={{
              width: "inherit",
              height: "30px",
              borderRadius: "8px 8px 0px 0px",
            }}
          />
        </div>
        <div style={{ borderRadius: "0px 0px 8px 8px" }}>
          <textarea
            style={{
              width: "100%",
              height: "150px",
              border: "none",
              borderRadius: "0px 0px 8px 8px",
              background: "#F6F6F6",
              display: "flex",
              alignItems: "start",
              justifyContent: "start",
            }}
          />
        </div>
      </div>
    </Col>
  );
};

export default EmployeeNote;
