import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";

interface IFAQDetailForm {
  isEdit?: boolean;
  selectedFAQ?: any;
  setFAQDetail?: any;
}

const FAQDetailForm: React.FC<IFAQDetailForm> = ({
  selectedFAQ,
  isEdit,
  setFAQDetail,
}) => {
  console.log(selectedFAQ);

  return (
    <Col>
      <Row>
        <Col sm={12} lg={2} md={12}>
          <div style={{ padding: "5px" }}>
            <p className="employee-title">
              <FormattedMessage id="FAQ ID" />
            </p>
            <input
              type="text"
              value={selectedFAQ?.id}
              disabled
              className="employee-form-input"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Title" />
            </p>
            <input
              type="text"
              value={selectedFAQ?.title}
              disabled={isEdit}
              onChange={(e) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Title (Ar)" />
            </p>
            <input
              type="text"
              value={selectedFAQ?.title_ar}
              disabled={isEdit}
              onChange={(e) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  title_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Description" />
            </p>
            <input
              type="text"
              value={selectedFAQ?.description}
              disabled={isEdit}
              onChange={(e) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Description (Ar)" />
            </p>
            <input
              type="text"
              value={selectedFAQ?.description_ar}
              disabled={isEdit}
              onChange={(e) => {
                setFAQDetail((prev: any) => ({
                  ...prev,
                  description_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};
export default FAQDetailForm;
