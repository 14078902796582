import React from "react";
import "./style.css";
import { Col, Row } from "react-bootstrap";

interface TabsData {
  request?: String;
  requestDate?: String;
  emirateID?: String;
  mobileNo?: String;
  service?: String;
  requesterName?: String;
  email?: String;
  address?: String;
}
const RequestTabForm: React.FC<TabsData> = ({
  request,
  requestDate,
  service,
  requesterName,
  emirateID,
  mobileNo,
  email,
  address,
}) => {
  return (
    <div className="tabColor">
      <Row>
        {request && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{request}</label>
            <input className="inputOfTab" />
          </Col>
        )}
        {requestDate && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{requestDate}</label>
            <input className="inputOfTab" />
          </Col>
        )}
        {service && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{service}</label>
            <input className="inputOfTab" />
          </Col>
        )}
        {requesterName && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{requesterName}</label>
            <input className="inputOfTab" />
          </Col>
        )}
        {emirateID && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{emirateID}</label>
            <input className="inputOfTab" />
          </Col>
        )}
        {mobileNo && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{mobileNo}</label>
            <input className="inputOfTab" />
          </Col>
        )}
        {email && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{email}</label>
            <input className="inputOfTab" />
          </Col>
        )}
        {address && (
          <Col xs={12} className="my-2">
            <label className="inputTitle">{address}</label>
            <input className="inputOfTab" />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default RequestTabForm;
