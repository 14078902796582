import { Dispatch, SetStateAction, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { RequestedPlants } from "../RequestedPlants";
import "./style.css";
import { CustomModal } from "../Modal";
import { EmployeeAttachment } from "../EmployeeAttachment";
import { RequestTabForm } from "../RequestTabForm";
import { Loading } from "../../pages/Loading";
import arrow from "../../assets/icons/arrow.png";
import close from "../../assets/icons/close.png";
import { ServicesKpi } from "../ServicesKpi";
import { Card } from "../Card";
import StarIcon from "../../assets/svg/StarIcon";
import UserIcon from "../../assets/icons/Icon-3.png";
import { MoreOutlined } from "@ant-design/icons";
import { CustomerKpi } from "../CustomerKpi";
import { TabComponent } from "../Tab";
import { Button } from "../Button";
import EmployeeNote from "../EmployeeNote/EmployeeNote";
import DragFiles from "../DragFiles/DragFiles";
export interface IRightSideBar {
  isSkelton: boolean;
  setLeftWidth: Dispatch<SetStateAction<string>>;
  leftWidth: string;
  selectedPage: string;
  isAdmin: boolean;
  rightSideAdminCards?: any
}

const RightSideBar: React.FC<IRightSideBar> = ({
  isSkelton,
  setLeftWidth,
  leftWidth,
  selectedPage,
  isAdmin,
  rightSideAdminCards
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("request_details");
  const [selectedTab2, setSelectedTab2] = useState("progress_log");
  const [isLoading, setIsLoading] = useState(false);
  // const openModal = () => {
  //   setModalOpen(true);
  // };

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleTabSelect = (selectedKey: any) => {
    setSelectedTab(selectedKey);
  };
  const handleTabSelect2 = (selectedKey: any) => {
    setSelectedTab2(selectedKey);
  };
  const tabs = [
    {
      event_key: "request_details",
      title: "Request Details",
      component: (
        <RequestTabForm
          request="Request No"
          requestDate="Request Date"
          service="Service name"
        />
      ),
    },
    {
      event_key: "requester_details",
      title: "Requester Details",
      component: (
        <RequestTabForm
          requesterName="Requester Name"
          emirateID="Requester Emirates ID"
          mobileNo="Mobile number"
          email="Email address"
          address="Address"
        />
      ),
    },
    {
      event_key: "attachment_details",
      title: "Request Attachments",
      component: (
        <div style={{ background: "#DAF4E1", padding: "10px" }}>
          <EmployeeAttachment />,
        </div>
      ),
    },
  ];
  const tabs2 = [
    {
      event_key: "progress_log",
      title: "Progress Log",
      component: (
        <RequestTabForm
          request="Request No"
          requestDate="Request Date"
          service="Service name"
        />
      ),
    },
    {
      event_key: "progress_flow",
      title: "Progress Flow",
      component: (
        <RequestTabForm
          requesterName="Requester Name"
          emirateID="Requester Emirates ID"
          mobileNo="Mobile number"
          email="Email address"
          address="Address"
        />
      ),
    },
  ];

  const width = parseInt(leftWidth?.split("%")[0]);
  const rightWidth = 100 - width;
  const newWidth = 392 + rightWidth;
  return (
    <>
      <Col
        className={
          isAdmin
            ? "right-side-bar-container-admin"
            : "right-side-bar-container"
        }
        style={{ height: width < 70 ? "" : isAdmin ? "" : "100vh" }}
      >
        {isAdmin && (
          <div>
            <div style={{ display: "flex", gap: "10px", paddingRight: "16px" }}>
              <Card
                icon={<StarIcon />}
                menu={<MoreOutlined className="MoreOutlined" />}
                title={"Employees"}
                count={rightSideAdminCards?.employees}
                color={"#6E4C3E"}
                isSkelton={isSkelton}
              />
              <Card
                icon={<StarIcon />}
                menu={<MoreOutlined className="MoreOutlined" />}
                title={"Customers"}
                count={rightSideAdminCards?.customers}
                color={"#3E6E4B"}
                isSkelton={isSkelton}
              />
            </div>
            <div style={{ display: "flex", gap: "10px", paddingRight: "16px" }}>
              <Card
                icon={<StarIcon />}
                menu={<MoreOutlined className="MoreOutlined" />}
                title={"Services"}
                count={rightSideAdminCards?.services}
                color={"#3E516E"}
                isSkelton={isSkelton}
              />
              <Card
                icon={<StarIcon />}
                menu={<MoreOutlined className="MoreOutlined" />}
                title={"Requests"}
                count={rightSideAdminCards?.requests}
                color={"#B09A49"}
                isSkelton={isSkelton}
              />
            </div>
            <ServicesKpi width={0} isSkelton={isSkelton} />
            <CustomerKpi width={0} />
          </div>
        )}
        {selectedPage == "requests"
          ? !isAdmin && (
              <Row>
                <Button
                  className={width < 70 ? "reply-button" : "reply-button-small"}
                  title={width < 70 ? "Reply" : <img src={arrow} />}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                />
              </Row>
            )
          : !isAdmin && (
              <Row
                style={{
                  display: width < 70 ? "" : "flex",
                  flexDirection: width < 70 ? "row" : "column",
                  gap: width < 70 ? "" : "16px",
                  position: "fixed",
                  top: 0,
                  zIndex: 100,
                  marginTop: "20px",
                }}
              >
                <Col lg={6}>
                  {isSkelton ? (
                    <SkeletonTheme baseColor="#CDCDCD" height={52} width={188}>
                      <div>
                        <Skeleton />
                      </div>
                    </SkeletonTheme>
                  ) : (
                    <Button
                      className={
                        width < 70 ? "send-button" : "send-button-small"
                      }
                      title={width < 70 ? "Send" : <img src={arrow} />}
                      onClick={() => {
                        setModalOpen(true);
                      }}
                    />
                  )}
                </Col>
                <Col lg={6}>
                  {isSkelton ? (
                    <SkeletonTheme baseColor="#CDCDCD" height={52} width={188}>
                      <div>
                        <Skeleton />
                      </div>
                    </SkeletonTheme>
                  ) : (
                    <Button
                      className={
                        width < 70 ? "reject-button" : "reject-button-small"
                      }
                      title={width < 70 ? "Reject" : <img src={close} />}
                      onClick={() => {}}
                    />
                  )}
                </Col>
              </Row>
            )}

        {/* Tabs Section Start */}
        <Row style={{ marginTop: "100px" }}>
          <Col sm={12}>
            {isSkelton ? (
              <SkeletonTheme baseColor="#CDCDCD" width={392} height={301}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            ) : width < 70 ? (
              !isAdmin && (
                <div
                  style={{
                    height: "301px",
                    width: width >= 65 ? "392px" : `100%`,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    paddingLeft: width >= 65 ? "0px" : "50px",
                    marginRight: width >= 65 ? "0px" : "20px",
                  }}
                >
                  <TabComponent
                    selectedTab={selectedTab}
                    handleTabSelect={handleTabSelect}
                    tabs={tabs}
                  />
                </div>
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
        {/* Tabs Section End */}

        {/* Plants Section Start */}
        {!isAdmin && (
          <Row
            style={{ width: "100%", paddingLeft: isSkelton ? "30px" : "25px" }}
          >
            <Col>
              {isSkelton ? (
                <SkeletonTheme baseColor="#CDCDCD" height={138} width={392}>
                  <div>
                    <Skeleton />
                  </div>
                </SkeletonTheme>
              ) : width < 70 ? (
                // <div style={{ width: width >= 35 ? "auto" : `100%` }}>
                <RequestedPlants width={width} />
              ) : (
                // </div>
                ""
              )}
            </Col>
          </Row>
        )}
        {/* Plants Section End */}

        {/* Employee Notes Section Start */}
        <Row>{width < 70 ? !isAdmin && <EmployeeNote /> : ""}</Row>
        {/* Employee Notes Section End */}

        {/* Employee Attachment Start */}
        {!isAdmin && (
          <Row
            style={{
              width: "100%",
              paddingLeft: isSkelton ? "30px" : "25px",
              paddingRight: "25px",
            }}
          >
            <Col>
              {isSkelton ? (
                <SkeletonTheme baseColor="#CDCDCD" height={301} width={392}>
                  <div>
                    <Skeleton />
                  </div>
                </SkeletonTheme>
              ) : width < 70 ? (
                <div style={{ width: "100%" }}>
                  <EmployeeAttachment />
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
        )}
        {/* Employee Attachment End */}
        {/* Drag and Drop Section Start */}
        <Row>
          {width < 70
            ? !isAdmin && (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "60px",
                    paddingRight: "60px",
                    marginTop: "20px",
                  }}
                >
                  <DragFiles />
                </div>
              )
            : ""}
        </Row>
        {/* Drag and Drop Section End */}
        {/* Tabs Section Start */}
        <Row style={{ marginTop: "10px" }}>
          <Col sm={12}>
            {width < 70
              ? !isAdmin && (
                  <div
                    style={{
                      height: "301px",
                      width: width >= 65 ? "392px" : `100%`,
                      overflowY: "scroll",
                      overflowX: "hidden",
                      paddingLeft: width >= 65 ? "0px" : "50px",
                      marginRight: width >= 65 ? "0px" : "20px",
                    }}
                  >
                    <TabComponent
                      selectedTab={selectedTab2}
                      handleTabSelect={handleTabSelect2}
                      tabs={tabs2}
                    />
                  </div>
                )
              : ""}
          </Col>
        </Row>
        {/* Tabs Section End */}
      </Col>
      {isModalOpen && (
        <CustomModal
          isOpen={isModalOpen}
          onClose={closeModal}
          setIsLoading={setIsLoading}
        />
      )}
      {isLoading && (
        <div
          style={{
            background: "rgb(99 99 99 / 9%);",
            // background: "red",
            position: "fixed",
            top: "0%",
            width: "100%",
            right: "0",
          }}
        >
          <Loading />
        </div>
      )}
    </>
  );
};

export default RightSideBar;
