import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
// import { useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";
// import { PersonalDetails ,WorkInformation} from "../../components/EmpDetailTabs/index";
import { ListDetailForm } from "../../components/ListDetail";
import { Button } from "../../components/Button";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";
import { MediaCenterDetailForm } from "../../components/MediaCenterDetail";
const FormData = require("form-data");
interface IMediaCenterDetail {
  locale?: string;
  setMediaCenterDetailPage: any;
  isEdit: any;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedMediaCenter: any;
  setRefetch: any;
  isLoading: boolean;
  setIsLoading: any;
}

const MediaCenterDetail: React.FC<IMediaCenterDetail> = ({
  locale,
  setMediaCenterDetailPage,
  isEdit,
  isEditDetail,
  setIsEdit,
  setIsEditDetail,
  selectedMediaCenter,
  setRefetch,
  isLoading,
  setIsLoading,
}) => {
  console.log("selectedMediaCenter", selectedMediaCenter);
  const [MediaCenterDetail, setMediaCenterDetail] = useState(
    isEdit
      ? selectedMediaCenter
      : {
          id: "",
          title: "",
          title_ar: "",
          description: "",
          description_ar: "",
          image: "",
          media_type: "",
          is_active: "",
        }
  );
  // console.log("MediaCenterDetail", MediaCenterDetail);

  const handleAddUpdateMediaCenter = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    setIsLoading(true);
    let data = new FormData();
    data.append("id", MediaCenterDetail?.id);
    data.append("title", MediaCenterDetail?.title);
    data.append("title_ar", MediaCenterDetail?.title_ar);
    data.append("media_type", MediaCenterDetail?.media_type);
    data.append("is_active", MediaCenterDetail?.is_active ? "1" : "0");

    data.append("user_id", userId);
    data.append("description", MediaCenterDetail?.description);
    data.append("description_ar", MediaCenterDetail?.description_ar);
    data.append("image", MediaCenterDetail?.image);
    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/MediaCenters/addEditImageArticle",
        data
      )
      .then((res) => {
        setIsEdit(true);
        setMediaCenterDetail((prev: any) => ({
          ...prev,
          id: res?.data?.ref,
        }));
        setRefetch((prev: any) => !prev);
        setMediaCenterDetailPage(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDeactivateMediaCenter = () => {
    Swal.fire({
      title: "Are you sure you want to do this?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      const token = sessionStorage.getItem("token");
      if (result.isConfirmed && token && userId) {
        let data = qs.stringify({
          media_center_id: MediaCenterDetail?.id,
          status: MediaCenterDetail?.is_active ? 0 : 1,
          user_id: userId,
          token: token,
        });

        axios
          .post(
            "https://appadmin.buyinpk.com/portalapis/MediaCenters/updateStatus",
            data
          )
          .then((res) => {
            setMediaCenterDetail((prev: any) => ({
              ...prev,
              is_active: !prev.is_active,
            }));
            setRefetch((prev: boolean) => !prev);
            setMediaCenterDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };

  const handleDeleteMediaCenter = () => {
    Swal.fire({
      title: "Are you sure you want to do this?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      if (result.isConfirmed && userId) {
        axios
          .post(
            `https://appadmin.buyinpk.com/portalapis/SystemMediaCenters/delete/${selectedMediaCenter?.id}?user_id=${userId}`
          )
          .then((res) => {
            console.log(res);
            setRefetch((prev: boolean) => !prev);
            setMediaCenterDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };

  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setMediaCenterDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p
                className="back-p"
                onClick={() => setMediaCenterDetailPage(false)}
              >
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">Add new Media</p>
            </Col>
          )}
          {/* <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={MediaCenterDetail?.is_active ? "Edit Details" : "Active"}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (MediaCenterDetail?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivateMediaCenter();
                  }
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={
                  !isEditDetail
                    ? "Add"
                    : isEdit
                    ? "Edit Details"
                    : "Save changes"
                }
                className={"save-changes-btn"}
                onClick={() => {
                  handleAddUpdateMediaCenter();
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setMediaCenterDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={MediaCenterDetail?.is_active ? "Deactivate" : "Delete"}
                className={"deactivate-btn"}
                onClick={() =>
                  MediaCenterDetail?.is_active
                    ? handleDeactivateMediaCenter()
                    : handleDeleteMediaCenter()
                }
              />
            )}
          </Col> */}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {/* Edit Details Button */}
            {isEdit && (
              <Button
                title="Edit Details"
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  setIsEdit(false);
                  setIsEditDetail(true);
                }}
              />
            )}
            {/* Save Changes Button */}
            {!isEdit && (
              <Button
                title={isEditDetail ? "Save Changes" : "Add"}
                className="save-changes-btn"
                onClick={() => {
                  handleAddUpdateMediaCenter();
                }}
              />
            )}
            {/* Active Button */}
            {(isEdit || isEditDetail) &&
              MediaCenterDetail?.is_active === false && (
                <Button
                  title="Active"
                  className="edit-details-btn"
                  hoverClassName=""
                  onClick={() => {
                    handleDeactivateMediaCenter();
                  }}
                />
              )}
            {!isEdit && (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setMediaCenterDetailPage(false);
                }}
              />
            )}
            {MediaCenterDetail?.is_active && (
              <Button
                title={"Deactivate"}
                className={"deactivate-btn"}
                onClick={() => {
                  if (MediaCenterDetail?.is_active) {
                    handleDeactivateMediaCenter();
                  }
                }}
              />
            )}
            {(isEdit || isEditDetail) && (
              <Button
                title={"Delete"}
                className={"deactivate-btn"}
                onClick={() => handleDeleteMediaCenter()}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">Media Center Details</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={12} lg={12} className="inputs-col">
            <MediaCenterDetailForm
              isEdit={isEdit}
              isEditDetail={isEditDetail}
              selectedMediaCenter={MediaCenterDetail}
              setMediaCenterDetail={setMediaCenterDetail}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MediaCenterDetail;
