import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
// import { useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";
// import { PersonalDetails ,WorkInformation} from "../../components/EmpDetailTabs/index";
import { ListDetailForm } from "../../components/ListDetail";
import { Button } from "../../components/Button";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { FAQDetailForm } from "../../components/FAQDetail";
const FormData = require("form-data");
interface IFAQDetail {
  locale?: string;
  setFAQSDetailPage: any;
  isEdit: any;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedFAQ: any;
  setRefetch: any;
  isLoading: boolean;
  setIsLoading: any;
}

const FAQDetail: React.FC<IFAQDetail> = ({
  locale,
  setFAQSDetailPage,
  isEdit,
  isEditDetail,
  setIsEdit,
  setIsEditDetail,
  selectedFAQ,
  setRefetch,
  isLoading,
  setIsLoading,
}) => {
  const [FAQDetail, setFAQDetail] = useState(
    isEdit
      ? selectedFAQ
      : {
          id: "",
          title: "",
          title_ar: "",
          description: "",
          description_ar: "",
          is_active: "",
          user_id: "",
        }
  );

  const handleAddUpdateFAQ = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    setIsLoading(true);
    let data = JSON.stringify({
      id: FAQDetail?.id,
      title: FAQDetail?.title,
      title_ar: FAQDetail?.title_ar,
      description: FAQDetail?.description,
      description_ar: FAQDetail?.description_ar,
      is_active: FAQDetail?.is_active ? FAQDetail?.is_active : 1,
      user_id: userId,
    });
    axios
      .post("https://appadmin.buyinpk.com/portalapis/Faqs/addEdit", data)
      .then((res) => {
        setIsEdit(true);
        setFAQDetail((prev: any) => ({
          ...prev,
          id: res?.data?.ref,
        }));
        setRefetch((prev: any) => !prev);
        setFAQSDetailPage(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDeactivateFAQ = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = JSON.stringify({
      user_id: userId,
      faq_id: FAQDetail?.id,
      status: FAQDetail?.is_active ? 0 : 1,
    });

    axios
      .post("https://appadmin.buyinpk.com/portalapis/Faqs/updateStatus", data)
      .then((res) => {
        setFAQDetail((prev: any) => ({
          ...prev,
          is_active: !prev.is_active,
        }));
        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteFAQ = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    const token = sessionStorage.getItem("token");
    let data = JSON.stringify({
      user_id: userId,
      faq_id: FAQDetail?.id,
      token: token,
    });
    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Faqs/delete`, data)
      .then((res) => {
        console.log(res);
        setRefetch((prev: boolean) => !prev);
        setFAQSDetailPage(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setFAQSDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p className="back-p" onClick={() => setFAQSDetailPage(false)}>
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">Add new FAQ</p>
            </Col>
          )}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={FAQDetail?.is_active ? "Edit Details" : "Active"}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (FAQDetail?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivateFAQ();
                  }
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={
                  !isEditDetail
                    ? "Add"
                    : isEdit
                    ? "Edit Details"
                    : "Save changes"
                }
                className={"save-changes-btn"}
                onClick={() => {
                  handleAddUpdateFAQ();
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setFAQSDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={FAQDetail?.is_active ? "Deactivate" : "Delete"}
                className={"deactivate-btn"}
                onClick={() =>
                  FAQDetail?.is_active
                    ? handleDeactivateFAQ()
                    : handleDeleteFAQ()
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">FAQ Details</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={12} lg={12} className="inputs-col">
            <FAQDetailForm
              isEdit={isEdit}
              selectedFAQ={FAQDetail}
              setFAQDetail={setFAQDetail}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FAQDetail;
