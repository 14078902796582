import React from 'react';

interface DashboardIconProps {
  width: number;
  height: number;
}
const SettingSearchIcon: React.FC<DashboardIconProps> = ({ width, height }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path d="M20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L20.2929 21.7071ZM16.8235 10.4118C16.8235 13.9529 13.9529 16.8235 10.4118 16.8235V18.8235C15.0575 18.8235 18.8235 15.0575 18.8235 10.4118H16.8235ZM10.4118 16.8235C6.87064 16.8235 4 13.9529 4 10.4118H2C2 15.0575 5.76608 18.8235 10.4118 18.8235V16.8235ZM4 10.4118C4 6.87064 6.87064 4 10.4118 4V2C5.76608 2 2 5.76608 2 10.4118H4ZM10.4118 4C13.9529 4 16.8235 6.87064 16.8235 10.4118H18.8235C18.8235 5.76608 15.0575 2 10.4118 2V4ZM14.9988 16.413L20.2929 21.7071L21.7071 20.2929L16.413 14.9988L14.9988 16.413Z" fill="#A5A5A5"/>
    </svg>
  );
}
export default SettingSearchIcon;
