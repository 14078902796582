import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./styles.css";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";

interface IHomeBannerDetailForm {
  isEdit?: boolean;
  selectedHomeBanner?: any;
  setHomeBannerDetail?: any;
  isEditDetail?: boolean;
}

const HomeBannerDetailForm: React.FC<IHomeBannerDetailForm> = ({
  selectedHomeBanner,
  isEdit,
  setHomeBannerDetail,
  isEditDetail,
}) => {
  console.log(selectedHomeBanner);
  const [uploadedImage, setUploadedImage] = useState<any>(
    selectedHomeBanner?.image ? selectedHomeBanner?.image : ""
  );
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles?.forEach((file: File) => {
        if (file) {
          setHomeBannerDetail((prev: any) => ({
            ...prev,
            image: file,
          }));
          const reader = new FileReader();
          reader.onloadend = () => {
            // After reading the file, set the uploaded image in the state
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      });
    },
    [selectedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });
  return (
    <Col>
      <Row>
        <Col sm={12} lg={2} md={12}>
          {selectedHomeBanner?.image ? (
            selectedHomeBanner?.media_type == "VIDEO" ? (
              <video width="200" height="200" controls>
                <source src={selectedHomeBanner?.image} type="video/mp4" />
              </video>
            ) : !isEdit && isEditDetail ? (
              <div
                style={{
                  background: `url(${uploadedImage})`,
                  height: "inherit",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  width: "100%",
                  borderRadius: "8px",
                }}
                // {...getInputProps()}
              >
                <input
                  type="file"
                  id="file-input"
                  data-testid="file-input"
                  style={{ width: "100%", height: "100%" }}
                  {...getInputProps()}
                />
                <label htmlFor="file-input"></label>
                <span
                  style={{
                    paddingRight: "10px",
                    paddingBottom: "10px",
                    color: "white",
                  }}
                >
                  Edit
                </span>
              </div>
            ) : (
              <div>
                <img className="image-div" src={uploadedImage} alt="Logo" />
              </div>
            )
          ) : (
            <div
              style={{
                width: "100%",

                height: "170px",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  background: "rgba(0, 0, 0, 0.50)",
                  height: "inherit",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                {...getRootProps()}
              >
                <input
                  type="file"
                  id="file-input"
                  data-testid="file-input"
                  {...getInputProps()}
                />

                <FiUpload color="white" size={30} />
                <label htmlFor="file-input"></label>
              </div>
            </div>
          )}
        </Col>
        <Col sm={12} lg={2} md={12}>
          <div style={{ padding: "5px" }}>
            <p className="employee-title">
              <FormattedMessage id="ID" />
            </p>
            <input
              type="text"
              value={selectedHomeBanner?.id}
              disabled
              className="employee-form-input"
            />
          </div>        
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Title" />
            </p>
            <input
              type="text"
              value={selectedHomeBanner?.title}
              disabled={isEdit}
              onChange={(e) => {
                setHomeBannerDetail((prev: any) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Title (Ar)" />
            </p>
            <input
              type="text"
              value={selectedHomeBanner?.title_ar}
              disabled={isEdit}
              onChange={(e) => {
                setHomeBannerDetail((prev: any) => ({
                  ...prev,
                  title_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
              style={{ textAlign: "right" }}
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Description" />
            </p>
            <input
              type="text"
              value={selectedHomeBanner?.description}
              disabled={isEdit}
              onChange={(e) => {
                setHomeBannerDetail((prev: any) => ({
                  ...prev,
                  description: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="p-1 my-2">
            <p className="employee-title">
              <FormattedMessage id="Description (Ar)" />
            </p>
            <input
              type="text"
              value={selectedHomeBanner?.description_ar}
              disabled={isEdit}
              onChange={(e) => {
                setHomeBannerDetail((prev: any) => ({
                  ...prev,
                  description_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
              style={{ textAlign: "right" }}
            />
          </div>
        </Col>
      </Row>
    </Col>
  );
};
export default HomeBannerDetailForm;
