import { Col, Row } from "react-bootstrap";

import "react-loading-skeleton/dist/skeleton.css";
import {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { SideBar } from "../../components/SideBar";
import "./style.css";
import { RightSideBar } from "../../components/RightSideBar";
import { EmployeeDashboard } from "../EmployeeDashboard";

import { DeleteOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, MenuProps, Switch, Tag } from "antd";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ColumnsType } from "antd/es/table";
import EmployeeDetail from "../EmployeeDetail";
import { Header } from "../../components/Header";
import { CustomTable } from "../../components/Table";
import TableHeader from "../../components/TableHeader/TableHeader";
import DepartmentDetail from "../DepartmentDetail";
import ListDetail from "../ListDetail";
import PlantDetail from "../PlantDetail";
import { SystemConfiguration } from "../../components/SystemConfiguration";
import ServiceDetail from "../ServiceDetail";
import NotificationDetail from "../NotificationDetail";
import SectionDetail from "../SectionDetail";
import axios from "axios";
import logo from "../../assets/images/logo.png";
import { Loading } from "../Loading";
import qs from "qs";
import FAQDetail from "../FAQDetail";
import MediaCenterDetail from "../MediaCenterDetail";
import HomeBannerDetail from "../HomeBannerDetail";

interface ILayout {
  isAdmin: boolean;
  isHead?: boolean;
  children?: PropsWithChildren<unknown>;
}

interface IEmployeeDataType {
  key: string;
  fullname: string;
  position: string;
  section: string;
  id: string;
  status: string;
}

interface IRequesterDataType {
  key: string;
  request_date: string;
  name: string;
  request_no: string;
  section: string;
  service_name: string;
  tags: string;
}
interface TagProps {
  color: string;
  bgColor: string;
  borderColor: string;
  textColor: string;
}

const Layout: React.FC<ILayout> = ({ isAdmin, isHead, children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [isSkelton, setIsSkelton] = useState(true);
  const [selectedPage, setSelectedPage] = useState("dashboard");
  const [employeeDetailPage, setEmployeeDetailPage] = useState(false);
  const [departmentDetailPage, setDepartmentDetailPage] = useState(false);
  const [selectedDepartment, setselectedDepartment] = useState();
  const [selectedEmployee, setSelectedEmployee] = useState<any>();
  const [selectedList, setSelectedList] = useState<any>();
  const [selectedPlant, setSelectedPlant] = useState<any>();
  const [selectedService, setSelectedService] = useState<any>();
  const [selectedSection, setSelectedSection] = useState<any>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedFAQ, setSelectedFAQ] = useState<any>();
  const [selectedMediaCenter, setSelectedMediaCenter] = useState<any>();
  const [selectedHomeBanner, setSelectedHomeBanner] = useState<any>();
  const [selectedNotification, setSelectedNotification] = useState<any>();
  const [listDetailPage, setListDetailPage] = useState(false);
  const [plantDetailPage, setPlantDetailPage] = useState(false);
  const [serviceDetailPage, setServiceDetailPage] = useState(false);
  const [notificationDetailPage, setNotificationDetailPage] = useState(false);
  const [customerDetailPage, setCustomerDetailPage] = useState(false);
  const [sectionDetailPage, setSectionDetailPage] = useState(false);
  const [faqsDetailPage, setFAQSDetailPage] = useState(false);
  const [mediaCenterDetailPage, setMediaCenterDetailPage] = useState(false);
  const [homeBannerDetailPage, setHomeBannerDetailPage] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState("table");
  const [rightSideAdminCards, setRightSideAdminCards] = useState({
    employees: 0,
    customers: 0,
    services: 0,
    requests: 0,
  });

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      count += 1;
      if (count == 4) {
        setIsSkelton(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const [isRightSidebar, setIsRightSidebar] = useState(true);
  const [selectedMenuItem, setSelectedMenu] = useState("Employees");
  // console.log("selectedMenuItem", selectedMenuItem);
  const [leftWidth, setLeftWidth] = useState(isAdmin ? "85%" : "65%");
  const [isMinimize, setIsMinimize] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleResize = (e: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", handleMouseMove);
      });
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const newLeftWidth = `${
        ((e.clientX - containerRect.left) / containerRect.width) * 100
      }%`;
      setLeftWidth(newLeftWidth);
    }
  };

  const EmployeeSkeltonColumns: ColumnsType<IEmployeeDataType> = [
    {
      title: (
        <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ),
      dataIndex: "id",
      render: (text) => (
        <SkeletonTheme baseColor="#CDCDCD" width={49} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ),
      sorter: (a, b) => a?.id?.length - b?.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Employee name",
      dataIndex: "fullname",
      render: (text, record) => (
        <SkeletonTheme baseColor="#CDCDCD" width={140} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ),

      sorter: (a, b) => a.fullname?.length - b.fullname?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Position",
      dataIndex: "position",
      render: (text, record) => (
        <SkeletonTheme baseColor="#CDCDCD" width={167} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ),
      sorter: (a, b) => a.position?.length - b.position?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Section",
      dataIndex: "section",
      render: (text, record) => (
        <SkeletonTheme baseColor="#CDCDCD" width={156} height={20}>
          <div>
            <Skeleton />
          </div>
        </SkeletonTheme>
      ),
      sorter: (a, b) => a.section?.length - b.section?.length,
      sortDirections: ["descend"],
    },

    {
      title: "Status",
      key: "tags",
      dataIndex: "status",
      render: (tag: string) => {
        let tagProps: TagProps = {
          color: "",
          bgColor: "white",
          borderColor: "transparent",
          textColor: "black",
        };

        if (tag === "Rejected") {
          tagProps = {
            color: "#C10606",
            bgColor: "#FFF5F5",
            borderColor: "transparent",
            textColor: "#C10606",
          };
        } else if (tag === "Done") {
          tagProps = {
            color: "#24AF0D",
            bgColor: "#F2FFEF",
            borderColor: "transparent",
            textColor: "#24AF0D",
          };
        } else if (tag === "On progress") {
          tagProps = {
            color: "#E0BE08",
            bgColor: "#FCF9E7",
            borderColor: "transparent",
            textColor: "#E0BE08",
          };
        }

        return (
          <SkeletonTheme baseColor="#CDCDCD" width={90} height={37}>
            <div>
              <Skeleton />
            </div>
          </SkeletonTheme>
        );
      },
      sorter: (a, b) => a.status?.length - b.status?.length,
      sortDirections: ["descend"],
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render: () => <MoreOutlined className="MoreOutlined" />,
    },
  ];
  const EmployeeColumns: ColumnsType<IEmployeeDataType> = [
    {
      title: "Number",
      dataIndex: "id",
      width: 4,
      render: (text) => (
        <span
          style={{
            fontSize: "16px",
            color: "#3E6E4B",
            fontFamily: "Alexandria",
            fontWeight: "700",
            wordWrap: "break-word",
          }}
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a?.id?.length - b?.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Employee name",
      dataIndex: "name",
      width: 10,
      render: (text, record) => {
        const parts = text?.split(",");
        return (
          <div
            className="avatar-name-container"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Avatar shape="square" size="large" icon={<img src={parts[1]} />} />
            <span>{parts[0]}</span>
          </div>
        );
      },

      sorter: (a, b) => a?.fullname?.length - b?.fullname?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Position",
      dataIndex: "position",
      width: 6,

      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a?.position?.length - b?.position?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Section",
      dataIndex: "section",
      width: 6,

      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a?.section?.length - b?.section?.length,
      sortDirections: ["descend"],
    },

    {
      title: "Status",
      key: "tag",
      width: 5,

      dataIndex: "current_status",
      render: (tag: string) => {
        let tagProps: TagProps = {
          color: "",
          bgColor: "white",
          borderColor: "transparent",
          textColor: "black",
        };

        if (tag === "Vacation") {
          tagProps = {
            color: "#C10606",
            bgColor: "#FFF5F5",
            borderColor: "transparent",
            textColor: "#C10606",
          };
        } else if (tag === "Online") {
          tagProps = {
            color: "#24AF0D",
            bgColor: "#F2FFEF",
            borderColor: "transparent",
            textColor: "#24AF0D",
          };
        } else if (tag === "Field Visit") {
          tagProps = {
            color: "#E0BE08",
            bgColor: "#FCF9E7",
            borderColor: "transparent",
            textColor: "#E0BE08",
          };
        }

        return (
          <Tag
            color={tagProps.color}
            key={tag}
            style={{
              color: tagProps.textColor,
              backgroundColor: tagProps.bgColor,
              borderColor: tagProps.borderColor,
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90px",
                height: "37px",
                fontFamily: "Alexandria",
              }}
            >
              {tag}
            </span>
          </Tag>
        );
      },
      // sorter: (a, b) => a.status.length - b.status.length,
      // sortDirections: ["descend"],
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      width: 2,

      dataIndex: "more",
      render: () => <MoreOutlined className="MoreOutlined" />,
    },
  ];
  const [EmployeesData, setEmployeesData] = useState([]);
  const [FilteredEmployeesData, setFilteredEmployeesData] = useState([]);

  const RequestersColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "number",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.number - b.number,
      sortDirections: ["descend"],
    },
    {
      title: "Request Date",
      dataIndex: "request_date",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.request_date?.length - b.request_date?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Request No.",
      dataIndex: "request_no",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.request_no?.length - b.request_no?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Section",
      dataIndex: "section",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.section?.length - b.section?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Serivce name",
      dataIndex: "service_name",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.service_name?.length - b.service_name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Step name",
      dataIndex: "service_step_name",
      render: (text, record) => {
        return <span>{text}</span>;
      },
      sorter: (a, b) =>
        a.service_step_name?.length - b.service_step_name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Position",
      dataIndex: "position_name",
      render: (text, record) => {
        // console.log(record);
        return <span>{text}</span>;
      },
      sorter: (a, b) => a.position_name?.length - b.position_name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (tag: string) => {
        let tagProps: TagProps = {
          color: "",
          bgColor: "white",
          borderColor: "transparent",
          textColor: "black",
        };

        if (tag === "Rejected") {
          tagProps = {
            color: "#C10606",
            bgColor: "#FFF5F5",
            borderColor: "transparent",
            textColor: "#C10606",
          };
        } else if (tag === "Done") {
          tagProps = {
            color: "#24AF0D",
            bgColor: "#F2FFEF",
            borderColor: "transparent",
            textColor: "#24AF0D",
          };
        } else if (tag === "PENDING") {
          tagProps = {
            color: "#E0BE08",
            bgColor: "#FCF9E7",
            borderColor: "transparent",
            textColor: "#E0BE08",
          };
        }

        return (
          <Tag
            color={tagProps.color}
            key={tag}
            style={{
              color: tagProps.textColor,
              backgroundColor: tagProps.bgColor,
              borderColor: tagProps.borderColor,
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90px",
                height: "37px",
                fontFamily: "Alexandria",
              }}
            >
              {tag}
            </span>
          </Tag>
        );
      },
      sorter: (a, b) => a.tags?.length - b.tags?.length,
      sortDirections: ["descend"],
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render: () => <MoreOutlined className="MoreOutlined" />,
    },
  ];
  const [RequestersData, setRequestersData] = useState([]);

  const [FilteredRequestersData, setFilteredRequestersData] = useState([]);
  const DepartmentsColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a?.id - b?.id,
      sortDirections: ["descend"],
    },
    {
      title: "Department Name",
      dataIndex: "name",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a?.name?.length - b?.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Head Of Section",
      dataIndex: "head_of_section",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a?.head_of_section?.length - b?.head_of_section?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a?.email?.length - b?.email?.length,
      sortDirections: ["descend"],
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
    },
  ];
  const [DepartmentsData, setDepartmentsData] = useState([]);
  const [FilteredDepartmentsData, setFilteredDepartmentsData] = useState([]);
  const handleDeactivateList = (record: any) => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = qs.stringify({
      list_id: record?.id,
      status: record?.is_active ? "0" : "1",
      user_id: userId,
    });

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/SystemLists/updateStatus",
        data
      )
      .then((res) => {
        localStorage.setItem("selectedSection", "list");

        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeactivateSection = (record: any) => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    const token = sessionStorage.getItem("token");

    let data = new FormData();
    data.append("mobile_section_id", record?.id);
    data.append("status", record?.is_active == "1" ? "0" : "1");
    data.append("user_id", userId);
    data.append("token", token ? token : "");

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/MobileSections/updateStatus",
        data
      )
      .then((res) => {
        localStorage.setItem("selectedSection", "section");
        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeactivateFAQ = (record: any) => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = JSON.stringify({
      user_id: userId,
      faq_id: record?.id,
      status: record?.is_active ? 0 : 1,
    });

    axios
      .post("https://appadmin.buyinpk.com/portalapis/Faqs/updateStatus", data)
      .then((res) => {
        localStorage.setItem("selectedSection", "faqs");

        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeactivateMediaCenter = (record: any) => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    const token = sessionStorage.getItem("token");

    let data = qs.stringify({
      media_center_id: record?.id,
      status: record?.is_active ? 0 : 1,
      user_id: userId,
      token: token,
    });

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/MediaCenters/updateStatus",
        data
      )
      .then((res) => {
        localStorage.setItem("selectedSection", "mediaCenter");
        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeactivateService = (record: any) => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = qs.stringify({
      service_id: record?.id,
      status: record?.is_active ? 0 : 1,
      user_id: userId,
    });

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/Services/updateStatus",
        data
      )
      .then((res) => {
        localStorage.setItem("selectedSection", "service");

        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDeactivatePlant = (record: any) => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = qs.stringify({
      status: record?.is_active ? 0 : 1,
      user_id: userId,
    });

    axios
      .post(
        `https://appadmin.buyinpk.com/portalapis/Plants/updateStatus/${record?.id}`,
        data
      )
      .then((res) => {
        localStorage.setItem("selectedSection", "plant");

        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleToggle = (checked: boolean, record: any, event: any) => {
    // Prevent row click event when toggling
    event.stopPropagation();
  };
  const handleTogglePlant = (checked: boolean, record: any, event: any) => {
    // Prevent row click event when toggling
    event.stopPropagation();
    handleDeactivatePlant(record);
  };

  const handleToggleList = (checked: boolean, record: any, event: any) => {
    // Prevent row click event when toggling
    event.stopPropagation();
    handleDeactivateList(record);
  };

  const handleToggleSection = (checked: boolean, record: any, event: any) => {
    // Prevent row click event when toggling
    event.stopPropagation();
    handleDeactivateSection(record);
  };

  const handleToggleFAQS = (checked: boolean, record: any, event: any) => {
    // Prevent row click event when toggling
    event.stopPropagation();
    handleDeactivateFAQ(record);
  };
  const handleToggleMediaCenter = (
    checked: boolean,
    record: any,
    event: any
  ) => {
    // Prevent row click event when toggling
    event.stopPropagation();
    handleDeactivateMediaCenter(record);
  };
  const handleToggleService = (checked: boolean, record: any, event: any) => {
    // Prevent row click event when toggling
    event.stopPropagation();
    handleDeactivateService(record);
  };
  const ListsColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id?.length - b.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "List Name",
      dataIndex: "name",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Value",
      dataIndex: "value",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.value?.length - b.value?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} />  */}
          Status
        </div>
      ),
      dataIndex: "is_active",
      render: (text, record) => (
        <span>
          <Switch
            checked={text}
            onChange={(checked, event) =>
              handleToggleList(checked, record, event)
            }
          />{" "}
          {text ? "Active" : "Didactive"}
        </span>
      ),
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
    },
  ];
  const [ListsData, setListsData] = useState([]);
  const [FilteredListsData, setFilteredListsData] = useState([]);

  const FAQsColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id?.length - b.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Title",
      dataIndex: "title",

      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.value?.length - b.value?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} />  */}
          Status
        </div>
      ),
      dataIndex: "is_active",

      render: (text, record) => (
        <span>
          <Switch
            checked={text}
            onChange={(checked, event) =>
              handleToggleFAQS(checked, record, event)
            }
          />{" "}
          {text ? "Active" : "De-active"}
        </span>
      ),
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
    },
  ];
  const [FAQsData, setFAQsData] = useState([]);

  const MediaCenterColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id?.length - b.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Title",
      dataIndex: "title",

      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Type",
      dataIndex: "media_type",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.media_type?.length - b.media_type?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} /> */}
          Status
        </div>
      ),
      dataIndex: "is_active",

      render: (text, record) => (
        <span>
          <Switch
            checked={text}
            onChange={(checked, event) =>
              handleToggleMediaCenter(checked, record, event)
            }
          />{" "}
          {text ? "Active" : "De-active"}
        </span>
      ),
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
    },
  ];
  const [MediaCenterData, setMediaCenterData] = useState([]);
  // console.log("🚀 ~ MediaCenterData:", MediaCenterData);
  const [HomeBannerData, setHomeBannerData] = useState([]);
  // console.log("🚀 ~ HomeBannerData:", HomeBannerData)
  const NotificationsColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id?.length - b.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Title",
      dataIndex: "title",

      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.media_type?.length - b.media_type?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} /> */}
          Email
        </div>
      ),
      dataIndex: "send_email",

      render: (text, record) => (
        <span>
          <Switch
            checked={text}
            onChange={(checked, event) => handleToggle(checked, record, event)}
          />{" "}
          {text ? "Active" : "De-active"}
        </span>
      ),
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} />  */}
          SMS
        </div>
      ),
      dataIndex: "send_sms",

      render: (text, record) => (
        <span>
          <Switch
            checked={text}
            onChange={(checked, event) => handleToggle(checked, record, event)}
          />{" "}
          {text ? "Active" : "De-active"}
        </span>
      ),
    },

    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
    },
  ];
  const [NotificationsData, setNotificationsData] = useState([]);

  const PlantsColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id?.length - b.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Plant Name",
      dataIndex: "name_with_image",
      // render: (text, record) => (
      //   <div
      //     className="avatar-name-container"
      //     style={{
      //       display: "flex",
      //       alignItems: "center",
      //       gap: "10px",
      //     }}
      //   >
      //     <Avatar
      //       shape="square"
      //       size="large"
      //       icon={<img src={logo} alt="plant" />}
      //     />
      //     <span>{text}</span>
      //   </div>
      // ),
      render(value, record, index) {
        const parts = value?.split(",");
        return (
          <div
            className="avatar-name-container"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Avatar shape="square" size="large" icon={<img src={parts[1]} />} />
            <span>{parts[0]}</span>
          </div>
        );
      },
      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Type",
      dataIndex: "category",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.category?.length - b.category?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} /> */}
          Status
        </div>
      ),
      dataIndex: "is_active",
      render: (text, record) => (
        <span>
          <Switch
            checked={text}
            onChange={(checked, event) =>
              handleTogglePlant(checked, record, event)
            }
          />{" "}
          {/* {text} */}
        </span>
      ),
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
    },
  ];
  const [PlantsData, setPlantsData] = useState([]);
  const [FilteredPlantsData, setFilteredPlantsData] = useState([]);

  const ServicesColumns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend"],
    },
    {
      title: "Service Name",
      dataIndex: "service_name",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.service_name?.length - b.service_name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Service Code",
      dataIndex: "service_code",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.service_code?.length - b.service_code?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text, record) => <span>{text}</span>,
      sorter: (a, b) => a.department?.length - b.department?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} />  */}
          Status
        </div>
      ),
      dataIndex: "is_active",
      render: (text, record) => {
        return (
          <span>
            <Switch
              checked={text}
              onChange={(checked, event) =>
                handleToggleService(checked, record, event)
              }
            />{" "}
            {/* {text} */}
          </span>
        );
      },
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
    },
  ];
  const [ServicesData, setServicesData] = useState([]);
  const [FilteredServicesData, setFilteredServicesData] = useState([]);

  const MobileSectionColumns: ColumnsType<any> = [
    {
      title: "Title",
      dataIndex: "title",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.title?.length - b.title?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} />  */}
          Status
        </div>
      ),
      dataIndex: "is_active",
      render: (text, record) => (
        <span>
          <Switch
            checked={text == "1" ? true : false}
            onChange={(checked, event) =>
              handleToggleSection(checked, record, event)
            }
          />{" "}
          {text == "1" ? "Active" : "De-active"}
        </span>
      ),
    },
    // {
    //   title: <MoreOutlined className="MoreOutlined" />,
    //   dataIndex: "more",
    //   render(value, record, index) {
    //     return selectedPage == "configuration" ? <DeleteOutlined /> : "";
    //   },
    // },
  ];
  const [MobileSectionData, setMobileSectionData] = useState([]);

  const CustomerColumns: ColumnsType<any> = [
    {
      title: "Number",
      dataIndex: "id",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend"],
    },
    {
      title: "Customer name",
      dataIndex: "fullname",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.title?.length - b.title?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Type",
      dataIndex: "job_title",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.job_title?.length - b.job_title?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Mobile",
      dataIndex: "mobile_number",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <span>{text}</span>
        </div>
      ),

      sorter: (a, b) => a.mobile_number?.length - b.mobile_number?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Account",
      key: "tags",
      dataIndex: "is_active",
      render: (tag: string) => {
        let tagProps: TagProps = {
          color: "",
          bgColor: "white",
          borderColor: "transparent",
          textColor: "black",
        };

        if (!tag) {
          tagProps = {
            color: "#C10606",
            bgColor: "#FFF5F5",
            borderColor: "transparent",
            textColor: "#C10606",
          };
        } else if (tag) {
          tagProps = {
            color: "#24AF0D",
            bgColor: "#F2FFEF",
            borderColor: "transparent",
            textColor: "#24AF0D",
          };
        } else {
          tagProps = {
            color: "#E0BE08",
            bgColor: "#FCF9E7",
            borderColor: "transparent",
            textColor: "#E0BE08",
          };
        }

        return (
          <Tag
            color={tagProps.color}
            key={tag}
            style={{
              color: tagProps.textColor,
              backgroundColor: tagProps.bgColor,
              borderColor: tagProps.borderColor,
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "90px",
                height: "37px",
                fontFamily: "Alexandria",
              }}
            >
              {tag ? "Active" : "De-active"}
            </span>
          </Tag>
        );
      },
      sorter: (a, b) => a.tags?.length - b.tags?.length,
      sortDirections: ["descend"],
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} />  */}
          Status
        </div>
      ),
      dataIndex: "is_active",
      render: (text, record) => (
        <span>
          <Switch
            checked={text}
            onChange={(checked, event) => handleToggle(checked, record, event)}
          />{" "}
          {/* {text ? "Active" : "De-active"} */}
        </span>
      ),
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render(value, record, index) {
        return selectedPage == "configuration" ? <DeleteOutlined /> : "";
      },
    },
  ];
  const [CustomersData, setCustomersData] = useState([]);
  const [FilteredCustomersData, setFilteredCustomersData] = useState([]);

  const [tableColumn, setTableColumn] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [numberOfResults, setNumberOfResults] = useState(0);
  const [isCustomer, setIsCustomer] = useState(false);
  const [counts, setCounts] = useState({
    employees: 0,
    departments: 0,
    lists: 0,
    services: 0,
    plants: 0,
    notifications: 0,
    customerAccounts: 0,
    mobileAppSections: 0,
    faqs: 0,
    mediaCenters: 0,
    homeBanners: 0,
  });

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const key = e.key;
    if (key == "1") {
      setSelectedMenu("Employees");
      setNumberOfResults(rightSideAdminCards?.employees);
      setTableColumn(EmployeeColumns);
      setTableData(SearchTerm ? FilteredEmployeesData : EmployeesData);
      localStorage.setItem("selectedSection", "employee");
    } else if (key == "2") {
      setSelectedMenu("Requesters");
      setNumberOfResults(rightSideAdminCards?.requests);
      setTableColumn(RequestersColumns);
      setTableData(SearchTerm ? FilteredRequestersData : RequestersData);
      localStorage.setItem("selectedSection", "requests");
    } else if (key == "3") {
      setSelectedMenu("Departments");
      setNumberOfResults(DepartmentsData?.length);
      setTableColumn(DepartmentsColumns);
      setTableData(SearchTerm ? FilteredDepartmentsData : DepartmentsData);
      localStorage.setItem("selectedSection", "department");
    } else if (key == "4") {
      setSelectedMenu("Lists");
      setNumberOfResults(ListsData?.length);
      setTableColumn(ListsColumns);
      setTableData(SearchTerm ? FilteredListsData : ListsData);
      localStorage.setItem("selectedSection", "list");
    } else if (key == "5") {
      setSelectedMenu("Plants");
      setNumberOfResults(PlantsData?.length);
      setTableColumn(PlantsColumns);
      setTableData(SearchTerm ? FilteredPlantsData : PlantsData);
      localStorage.setItem("selectedSection", "plant");
    } else if (key == "6") {
      setSelectedMenu("Services");
      setNumberOfResults(rightSideAdminCards?.services);
      setTableColumn(ServicesColumns);
      setTableData(SearchTerm ? FilteredServicesData : ServicesData);
      localStorage.setItem("selectedSection", "service");
    }
  };
  const handleConfigurationCardsClick = (key: number) => {
    if (key == 0) {
      setSelectedMenu("Employees");
      setNumberOfResults(EmployeesData?.length);
      setTableColumn(EmployeeColumns);
      setTableData(SearchTerm ? FilteredEmployeesData : EmployeesData);
      localStorage.setItem("selectedSection", "employee");
    } else if (key == 1) {
      setSelectedMenu("Departments");
      setNumberOfResults(DepartmentsData?.length);
      setTableColumn(DepartmentsColumns);
      setTableData(SearchTerm ? FilteredDepartmentsData : DepartmentsData);
      localStorage.setItem("selectedSection", "department");
    } else if (key == 2) {
      setSelectedMenu("Lists");
      setNumberOfResults(ListsData?.length);
      setTableColumn(ListsColumns);
      setTableData(SearchTerm ? FilteredListsData : ListsData);
      localStorage.setItem("selectedSection", "list");
    } else if (key == 3) {
      setSelectedMenu("Services");
      setNumberOfResults(rightSideAdminCards?.services);
      setTableColumn(ServicesColumns);
      setTableData(SearchTerm ? FilteredServicesData : ServicesData);
      localStorage.setItem("selectedSection", "service");
    } else if (key == 4) {
      setSelectedMenu("Plants");
      setNumberOfResults(PlantsData?.length);
      setTableColumn(PlantsColumns);
      setTableData(SearchTerm ? FilteredPlantsData : PlantsData);
      localStorage.setItem("selectedSection", "plant");
    } else if (key == 5) {
      setSelectedMenu("Notifications");
      setNumberOfResults(NotificationsData?.length);
      setTableColumn(NotificationsColumns);
      setTableData(NotificationsData);
      localStorage.setItem("selectedSection", "notification");
    } else if (key == 6) {
      setSelectedMenu("Customer Accounts");
      setNumberOfResults(CustomersData?.length);
      setTableColumn(CustomerColumns);
      setTableData(SearchTerm ? FilteredCustomersData : CustomersData);
      localStorage.setItem("selectedSection", "customerAccounts");
    } else if (key == 7) {
      setSelectedMenu("Mobile App Section");
      setNumberOfResults(MobileSectionData?.length);
      setTableColumn(MobileSectionColumns);
      setTableData(MobileSectionData);
      localStorage.setItem("selectedSection", "Section");
    } else if (key == 8) {
      setSelectedMenu("الاسئلة الاكثر تكرارا");
      setNumberOfResults(FAQsData?.length);
      setTableColumn(FAQsColumns);
      setTableData(FAQsData);
      localStorage.setItem("selectedSection", "faqs");
    } else if (key == 9) {
      setSelectedMenu("Media Center");
      setNumberOfResults(MediaCenterData?.length);
      setTableColumn(MediaCenterColumns);
      setTableData(MediaCenterData);
      localStorage.setItem("selectedSection", "mediaCenter");
    } else if (key == 10) {
      setSelectedMenu("Home Banner");
      setNumberOfResults(HomeBannerData?.length);
      setTableColumn(MediaCenterColumns);
      setTableData(HomeBannerData);
      localStorage.setItem("selectedSection", "homeBanner");
    }
  };
  const [isEditDetail, setIsEditDetail] = useState(false);

  const onRow = (record: any) => {
    setSelectedPage("configuration");
    if (record.data == "employee") {
      localStorage.setItem("selectedSection", "employee");
      setSelectedEmployee(record);
      setIsEdit(true);
      setDepartmentDetailPage(false);
      setListDetailPage(false);
      setPlantDetailPage(false);
      setEmployeeDetailPage(true);
    } else if (record.data == "department") {
      localStorage.setItem("selectedSection", "department");
      setselectedDepartment(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setListDetailPage(false);
      setPlantDetailPage(false);
      setServiceDetailPage(false);
      setDepartmentDetailPage(true);
    } else if (record.data == "list") {
      localStorage.setItem("selectedSection", "list");

      setSelectedList(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setServiceDetailPage(false);
      setListDetailPage(true);
    } else if (record.data == "plant") {
      localStorage.setItem("selectedSection", "plant");

      setSelectedPlant(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(false);
      setPlantDetailPage(true);
    } else if (record.data == "service") {
      localStorage.setItem("selectedSection", "service");

      setSelectedService(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(true);
    } else if (record.data == "customer") {
      localStorage.setItem("selectedSection", "customer");

      setSelectedCustomer(record);
      setIsEdit(true);
      setIsCustomer(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(false);
      setSectionDetailPage(false);
      setCustomerDetailPage(true);
    } else if (record.data == "section") {
      localStorage.setItem("selectedSection", "section");

      setSelectedSection(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(false);
      setSectionDetailPage(true);
    } else if (record.data == "faqs") {
      localStorage.setItem("selectedSection", "faqs");

      setSelectedFAQ(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(false);
      setFAQSDetailPage(true);
    } else if (record.data == "mediaCenter") {
      localStorage.setItem("selectedSection", "mediaCenter");

      setSelectedMediaCenter(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(false);
      setFAQSDetailPage(false);
      setMediaCenterDetailPage(true);
    } else if (record.data == "homeBanner") {
      localStorage.setItem("selectedSection", "homeBanner");

      setSelectedHomeBanner(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(false);
      setFAQSDetailPage(false);
      setMediaCenterDetailPage(false);
      setHomeBannerDetailPage(true);
    } else if (record.data == "notification") {
      localStorage.setItem("selectedSection", "notification");

      setSelectedNotification(record);
      setIsEdit(true);
      setEmployeeDetailPage(false);
      setDepartmentDetailPage(false);
      setPlantDetailPage(false);
      setListDetailPage(false);
      setServiceDetailPage(false);
      setFAQSDetailPage(false);
      setMediaCenterDetailPage(false);
      setNotificationDetailPage(true);
    }
  };
  const [refetch, setRefetch] = useState<any>();
  const [SearchTerm, setSearchTerm] = useState<any>();
  useEffect(() => {
    if (selectedPage === "requests") {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      const token = sessionStorage.getItem("token");
      setIsLoading(true);

      const body = {
        user_id: userId,
        token: token,
        module: "REQUESTS",
      };

      axios
        .post(`https://appadmin.buyinpk.com/portalapis/Admin/dashboard`, body)
        .then((response: any) => {
          let tempArr: any = [];
          response?.data?.requests?.data.map((d: any) => {
            // console.log("d",d);
            const approvals = d?.approvals || [];
            approvals.forEach((approval: any) => {
              tempArr.push({
                number: d?.id,
                request_date: d?.created?.split(" ")[0],
                name: d?.customer?.fullname,
                request_no: d?.id,
                section: d?.department?.name == null ? "" : d?.department?.name,
                service_name: d?.service?.name,
                tags: d?.request_status,
                data: "requester",
                position_name: approval?.position?.name,
                service_step_name: approval?.service_step?.step_name,
              });
            });
          });
          setRequestersData(tempArr);
          setIsLoading(false);
        })
        .catch((err: any) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [selectedPage]);
  useEffect(() => {
    setIsLoading(true);
    setSelectedPage("dashboard");
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    const token = sessionStorage.getItem("token");

    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Admin/getBoxCounts`)
      .then((response: any) => {
        setCounts((prev) => ({
          ...prev,
          employees: response?.data?.data?.counts?.employees,
          departments: response?.data?.data?.counts?.departments,
          lists: response?.data?.data?.counts?.lists,
          services: response?.data?.data?.counts?.services,
          plants: response?.data?.data?.counts?.plants,
          customerAccounts: response?.data?.data?.counts?.customers,
          mobileAppSections: response?.data?.data?.counts?.mobilesection,
          faqs: response?.data?.data?.counts?.faqs,
          mediaCenters: response?.data?.data?.counts?.mediacenter,
          notifications: response?.data?.data?.counts?.getnotifications,
        }));
        setRightSideAdminCards((prev) => ({
          ...prev,
          requests: response?.data?.data?.counts?.employees,
          employees: response?.data?.data?.counts?.employees,
          services: response?.data?.data?.counts?.services,
          customers: response?.data?.data?.counts?.customers,
        }));
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });

    const body = {
      user_id: userId,
      token: token,
      module:
        selectedMenuItem === "Employees"
          ? "EMPLOYEE"
          : selectedMenuItem === "Requesters"
          ? "REQUESTS"
          : selectedMenuItem === "Departments"
          ? "DEPARTMENTS"
          : selectedMenuItem === "Lists"
          ? "LISTS"
          : selectedMenuItem === "Plants"
          ? "PLANTS"
          : selectedMenuItem === "Services"
          ? "SERVICES"
          : selectedMenuItem === "Customer Accounts"
          ? "CUSTOMERS"
          : selectedMenuItem === "Mobile App Section"
          ? "MOBILE_SECTIONS"
          : selectedMenuItem === "الاسئلة الاكثر تكرارا"
          ? "FAQS"
          : selectedMenuItem === "Media Center"
          ? "MEDIACENTER"
          : selectedMenuItem === "Notifications"
          ? "NOTIFICATIONS"
          : selectedMenuItem === "Home Banner"
          ? "MEDIACENTER"
          : "EMPLOYEE",

      /**
      EMPLOYEE
      REQUESTS
      DEPARTMENTS
      LISTS
      PLANTS
      SERVICES
      CUSTOMERS
      MOBILE_SECTIONS
      FAQS
      MEDIACENTER
      NOTIFICATIONS
      */
    };
    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Admin/dashboard`, body)
      .then((response: any) => {
        let tempArr: any = [];

        /* For Employees */
        response?.data?.employees?.data?.map((d: any, index: number) => {
          const positions = d?.employee?.employeepositions?.map(
            (position: any) => {
              // console.log("position", position);
              return position?.designation_id;
            }
          );
          // console.log("positions", positions);
          tempArr.push({
            id: d?.employee?.id,
            fullname: d?.employee?.fullname,
            name: d?.employee?.fullname + "," + d?.employee?.image,
            fullname_ar: d?.employee?.fullname_ar,
            employee_type_id: d?.employee?.employeetype?.id,
            permissions: d?.employee?.employeepermissions?.map((p: any) => {
              return {
                module_id: p.module_id,
                has_rights:
                  p.is_active /***: 0 => Not have,  1: Has rights  ***/,
                permission: p.permissions,
              };
            }),
            designation_id: d?.employee?.designation?.id,
            emiratesid: d?.employee?.emiratesid,
            country_id: d?.employee?.country?.id,
            nationality: d?.employee?.country?.country_enNationality,
            mobile: d?.employee?.mobile,
            email: d?.employee?.email,
            pasword: d?.employee?.pasword,
            is_active: d?.employee?.is_active,
            department_id: d?.employee?.department?.id,
            department_manager: response?.data?.employees?.data?.filter(
              (dep: any) =>
                dep?.employee?.id == d?.employee?.department?.employee_id
            ),
            address: d?.employee?.address,
            mother_name: d?.employee?.mother_name,
            passport_no: d?.employee?.passport_no,
            union_no: d?.employee?.union_no,
            office_no: d?.employee?.office_no,
            home_email: d?.employee?.home_email,
            home_mobile: d?.employee?.home_mobile,
            image: d?.employee?.image,
            position: d?.employee?.designation?.name,
            section: d?.employee?.designation?.name,
            current_status: d?.employee?.current_status,
            job_title_id: d?.employee?.job_title_id,
            position_id: positions || [],
            // positions: d?.employee?.employeepositions,
            line_manager_id: d?.employee?.line_manager_id,
            can_sign_as_manager: d?.employee?.can_sign_as_manager,
            data: "employee",
          });
        });
        setEmployeesData(tempArr);

        // setRightSideAdminCards((prev) => ({
        //   ...prev,
        //   employees: response?.data?.employees?.count,
        // }));

        setNumberOfResults(response?.data?.employees?.count);
        /* For Requests */
        tempArr = [];
        response?.data?.requests?.data?.map((d: any) => {
          // console.log("d",d);
          const approvals = d?.approvals || [];
          approvals.forEach((approval: any) => {
            tempArr.push({
              number: d?.id,
              request_date: d?.created?.split(" ")[0],
              name: d?.customer?.fullname,
              request_no: d?.id,
              section: d?.department?.name == null ? "" : d?.department?.name,
              service_name: d?.service?.name,
              tags: d?.request_status,
              data: "requester",
              position_name: approval?.position?.name,
              service_step_name: approval?.service_step?.step_name,
            });
          });

          // tempArr.push({
          //   number: d?.id,
          //   request_date: d?.created?.split(" ")[0],
          //   name: d?.customer?.fullname,
          //   request_no: d?.id,
          //   section:
          //     d?.department?.name == null ? "Nothing" : d?.department?.name,
          //   service_name: d?.service?.name,
          //   tags: d?.request_status,
          //   data: "requester",
          // });
        });

        setRequestersData(tempArr);

        // setRightSideAdminCards((prev) => ({
        //   ...prev,
        //   requests: response?.data?.requests?.count,
        // }));

        /* For Departments */
        tempArr = [];
        response?.data?.departments?.data.map((d: any) => {
          tempArr.push({
            id: d?.department?.id,
            name: d?.department?.name,
            name_ar: d?.department?.name_ar,
            description: d?.department?.description,
            description_ar: d?.department?.description_ar,
            image: d?.department?.image,
            is_agriculture: d?.department?.is_agriculture,
            is_active: d?.department?.is_active,
            lat: d?.department?.geo_lat,
            lng: d?.department?.geo_lng,
            employee_id: d?.department?.employee?.id,
            head_of_section: d?.department?.employee?.fullname,
            email: d?.department?.email,
            mobile_no: d?.department?.mobile_no,
            data: "department",
          });
        });
        setDepartmentsData(tempArr);

        /* For Lists */
        tempArr = [];
        response?.data?.lists?.data.map((d: any) => {
          tempArr.push({
            id: d?.id,
            name: d?.list_name,
            name_ar: d?.list_name_ar,
            value: d?.list_value,
            value_ar: d?.list_value_ar,
            is_active: d?.is_active,
            data: "list",
          });
        });
        setListsData(tempArr);

        /* For Plants */
        tempArr = [];
        response?.data?.plants?.data.map((d: any) => {
          tempArr.push({
            id: d?.id,
            name_with_image: d?.name + "," + d?.image,
            name: d?.name,
            name_ar: d?.name_ar,
            quantity: d?.quantity,
            description: d?.description,
            description_ar: d?.description_ar,
            category: d?.category,
            image: d?.image,
            images: d?.images,
            department_id: d?.department_id,
            plant_height: d?.plant_height,
            is_active: d?.is_active,
            color: d?.color,
            data: "plant",
          });
        });
        setPlantsData(tempArr);

        /* For Services */
        tempArr = [];
        response?.data?.services?.data.map((d: any) => {
          tempArr.push({
            id: d?.id,
            service_name: d?.name,
            service_name_ar: d?.name_ar,
            service_code: d?.code,
            description: d?.description,
            description_ar: d?.description_ar,
            where_to_apply: d?.where_to_apply,
            where_to_apply_ar: d?.where_to_apply_ar,
            fee: d?.fee,
            fee_ar: d?.fee_ar,
            work_hours: d?.work_hours,
            work_hours_ar: d?.work_hours_ar,
            contact_no: d?.contact_no,
            total_service_time: d?.total_service_time,
            no_of_days: d?.no_of_days,
            additional_notes: d?.additional_notes,
            terms_and_conditions: d?.terms_and_conditions,
            terms_and_conditions_ar: d?.terms_and_conditions_ar,
            department: d?.department?.name,
            department_id: d?.department?.id,
            service_fields: d?.service_fields,
            service_steps: d?.service_steps,
            is_active: d?.is_active,
            data: "service",
          });
        });
        setServicesData(tempArr);
        // setRightSideAdminCards((prev) => ({
        //   ...prev,
        //   services: response?.data?.services?.count,
        // }));

        /* For Customers */
        tempArr = [];
        response?.data?.customers?.data?.map((d: any, index: number) => {
          tempArr.push({
            id: d?.id,
            fullname: d?.fullname,
            job_title: d?.job_title,
            mobile_number: d?.mobile_no,
            phone_no: d?.phone_no,
            email: d?.email,
            username: d?.username,
            emiratesid: d?.emiratesid,
            eid_expiry: d?.eid_expiry,
            eid_front: d?.eid_front,
            eid_back: d?.eid_back,
            address_area: d?.address_area,
            address_street: d?.address_street,
            address_building: d?.address_building,
            address_floor: d?.address_floor,
            address_flat: d?.address_flat,
            address_landmark: d?.address_landmark,
            address_gps: d?.address_gps,
            password: d?.password,
            is_active: d?.is_active,
            modified: d?.modified,
            created: d?.created,
            otp: d?.otp,
            otp_activated: d?.otp_activated,
            otp_dt: d?.otp_dt,
            is_deleted: d?.is_deleted,
            image: d?.image,
            data: "customer",
          });
        });
        setCustomersData(tempArr);
        // setRightSideAdminCards((prev) => ({
        //   ...prev,
        //   customers: response?.data?.customers?.count,
        // }));

        /* For Mobile Section */
        tempArr = [];
        response?.data?.mobilesection?.data?.map((d: any, index: number) => {
          tempArr.push({
            id: d?.id,
            title: d?.title,
            title_ar: d?.title_ar,
            position: d?.position,
            text: d?.text,
            text_ar: d?.text_ar,
            is_active: d?.is_active,
            modified: d?.modified,
            created: d?.created,
            page: d?.page,
            data: "section",
          });
        });
        setMobileSectionData(tempArr);

        /* For FAQS */
        tempArr = [];
        response?.data?.faqs?.data.map((d: any) => {
          tempArr.push({
            id: d?.id,
            title: d?.title,
            title_ar: d?.title_ar,
            description: d?.description,
            description_ar: d?.description_ar,
            is_active: d?.is_active,
            modified: d?.modified,
            created: d?.created,
            data: "faqs",
          });
        });
        setFAQsData(tempArr);

        /* For Media Center */
        tempArr = [];
        const tempArr2: any = [];
        if (selectedMenuItem === "Media Center") {
          response?.data?.mediacenter?.data.map((d: any) => {
            if (d?.media_type != "HOME") {
              tempArr.push({
                id: d?.id,
                media_type: d?.media_type,
                image: d?.image,
                video_file: d?.video_file,
                title: d?.title,
                description: d?.description,
                is_active: d?.is_active,
                modified: d?.modified,
                created: d?.created,
                title_ar: d?.title_ar,
                description_ar: d?.description_ar,
                data: "mediaCenter",
              });
            }
          });
        }
        if (selectedMenuItem === "Home Banner") {
          response?.data?.mediacenter?.data.map((d: any) => {
            if (d?.media_type == "HOME") {
              tempArr2.push({
                id: d?.id,
                media_type: d?.media_type,
                image: d?.image,
                video_file: d?.video_file,
                title: d?.title,
                description: d?.description,
                is_active: d?.is_active,
                modified: d?.modified,
                created: d?.created,
                title_ar: d?.title_ar,
                description_ar: d?.description_ar,
                data: "homeBanner",
              });
            }
          });
        }

        setMediaCenterData(tempArr);
        setCounts((prev) => ({ ...prev, homeBanners: tempArr2?.length }));
        setHomeBannerData(tempArr2);
        /* For Notifiactions */
        tempArr = [];
        response?.data?.getnotifications?.data.map((d: any) => {
          tempArr.push({
            id: d?.id,
            code: d?.code,
            title: d?.title,
            title_ar: d?.title_ar,
            type: d?.type,
            send_sms: d?.send_sms,
            send_email: d?.send_email,
            description: d?.description,
            description_ar: d?.description_ar,
            modified: d?.modified,
            created: d?.created,
            is_active: d?.is_active,
            image: d?.image,
            data: "notification",
          });
        });
        setNotificationsData(tempArr);

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [refetch, selectedMenuItem]);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (selectedPage == "requests") {
  //     // setSelectedPage("requests");
  //     // localStorage.setItem("selectedSection", "requests");
  //     const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
  //     const token = sessionStorage.getItem("token");

  //     const body = {
  //       user_id: userId,
  //       token: token,
  //       module: "REQUESTS",
  //     };
  //     axios
  //       .post(`https://appadmin.buyinpk.com/portalapis/Admin/dashboard`, body)
  //       .then((response: any) => {
  //         let tempArr: any = [];
  //         setNumberOfResults(response?.data?.employees?.count);
  //         /* For Requests */
  //         tempArr = [];
  //         response?.data?.requests?.data.map((d: any) => {
  //           // console.log("d",d);
  //           const approvals = d?.approvals || [];
  //           approvals.forEach((approval: any) => {
  //             tempArr.push({
  //               number: d?.id,
  //               request_date: d?.created?.split(" ")[0],
  //               name: d?.customer?.fullname,
  //               request_no: d?.id,
  //               section:
  //                 d?.department?.name == null ? "Nothing" : d?.department?.name,
  //               service_name: d?.service?.name,
  //               tags: d?.request_status,
  //               data: "requester",
  //               position_name: approval?.position?.name,
  //               service_step_name: approval?.service_step?.step_name,
  //             });
  //           });
  //         });

  //         setRequestersData(tempArr);
  //         // setRightSideAdminCards((prev) => ({
  //         //   ...prev,
  //         //   requests: response?.data?.requests?.count,
  //         // }));

  //         setIsLoading(false);
  //       })
  //       .catch((err: any) => {
  //         console.log(err);
  //         setIsLoading(false);
  //       });
  //   }
  // }, [selectedPage]);

  useEffect(() => {
    const selected = localStorage.getItem("selectedSection");

    if (selected == "employee") {
      setSelectedMenu("Employees");
      setNumberOfResults(rightSideAdminCards?.employees);
      setTableColumn(EmployeeColumns);
      setTableData(SearchTerm ? FilteredEmployeesData : EmployeesData);
    } else if (selected == "list") {
      setSelectedMenu("Lists");
      setNumberOfResults(ListsData?.length);
      setTableColumn(ListsColumns);
      setTableData(SearchTerm ? FilteredListsData : ListsData);
    } else if (selected == "requests") {
      setSelectedMenu("Requesters");
      setNumberOfResults(rightSideAdminCards?.requests);
      setTableColumn(RequestersColumns);
      setTableData(SearchTerm ? FilteredRequestersData : RequestersData);
    } else if (selected == "plant") {
      setSelectedMenu("Plants");
      setNumberOfResults(PlantsData?.length);
      setTableColumn(PlantsColumns);
      setTableData(SearchTerm ? FilteredPlantsData : PlantsData);
    } else if (selected == "service") {
      setSelectedMenu("Services");
      setNumberOfResults(rightSideAdminCards?.services);
      setTableColumn(ServicesColumns);
      setTableData(SearchTerm ? FilteredServicesData : ServicesData);
    } else if (selected == "department") {
      setSelectedMenu("Departments");
      setNumberOfResults(DepartmentsData?.length);
      setTableColumn(DepartmentsColumns);
      setTableData(SearchTerm ? FilteredDepartmentsData : DepartmentsData);
    } else if (selected == "section") {
      setSelectedMenu("Section");
      setNumberOfResults(MobileSectionData?.length);
      setTableColumn(MobileSectionColumns);
      setTableData(MobileSectionData);
    } else if (selected == "faqs") {
      setSelectedMenu("الاسئلة الاكثر تكرارا");
      setNumberOfResults(FAQsData?.length);
      setTableColumn(FAQsColumns);
      setTableData(FAQsData);
    } else if (selected == "customerAccounts") {
      setSelectedMenu("Customer Accounts");
      setNumberOfResults(CustomersData?.length);
      setTableColumn(CustomerColumns);
      setTableData(SearchTerm ? FilteredCustomersData : CustomersData);
    } else if (selected == "mediaCenter") {
      setSelectedMenu("Media Center");
      setNumberOfResults(MediaCenterData?.length);
      setTableColumn(MediaCenterColumns);
      setTableData(MediaCenterData);
    } else if (selected == "homeBanner") {
      setSelectedMenu("Home Banner");
      setNumberOfResults(HomeBannerData?.length);
      setTableColumn(MediaCenterColumns);
      setTableData(HomeBannerData);
    } else if (selected == "notification") {
      setSelectedMenu("notification");
      setNumberOfResults(NotificationsData?.length);
      setTableColumn(NotificationsColumns);
      setTableData(NotificationsData);
    } else {
      setTableColumn(EmployeeColumns);
      setTableData(SearchTerm ? FilteredEmployeesData : EmployeesData);
    }
  }, [EmployeesData, SearchTerm]);

  const handleSearch = (event: any) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    // Filter Employee
    const filteredEmployees = EmployeesData.filter((employee: any) => {
      return (
        (employee.fullname &&
          employee.fullname.toLowerCase().includes(value)) ||
        (employee.position &&
          employee.position.toLowerCase().includes(value)) ||
        (employee.section && employee.section.toLowerCase().includes(value))
      );
    });
    setFilteredEmployeesData(filteredEmployees);
    // Filter Request
    const filteredRequests = RequestersData.filter((requester: any) => {
      return (
        (requester.name && requester.name.toLowerCase().includes(value)) ||
        (requester.section &&
          requester.section.toLowerCase().includes(value)) ||
        (requester.service_name &&
          requester.service_name.toLowerCase().includes(value)) ||
        (requester.service_step_name &&
          requester.service_step_name.toLowerCase().includes(value)) ||
        (requester.position_name &&
          requester.position_name.toLowerCase().includes(value))
      );
    });

    setFilteredRequestersData(filteredRequests);
    // Filter Department
    const filteredDepartments = DepartmentsData.filter((department: any) => {
      return (
        (department.name && department.name.toLowerCase().includes(value)) ||
        (department.head_of_section &&
          department.head_of_section.toLowerCase().includes(value))
      );
    });

    setFilteredDepartmentsData(filteredDepartments);

    // Filter Lists
    const filteredLists = ListsData.filter((lists: any) => {
      return (
        (lists.name && lists.name.toLowerCase().includes(value)) ||
        (lists.value && lists.value.toLowerCase().includes(value))
      );
    });
    setFilteredListsData(filteredLists);

    // Filter Plants
    const filteredPlants = PlantsData.filter((plants: any) => {
      return (
        (plants.name && plants.name.toLowerCase().includes(value)) ||
        (plants.category && plants.category.toLowerCase().includes(value)) ||
        (plants.color && plants.color.toLowerCase().includes(value))
      );
    });
    setFilteredPlantsData(filteredPlants);

    // Filter Services
    const filteredServices = ServicesData.filter((services: any) => {
      return (
        (services.service_name &&
          services.service_name.toLowerCase().includes(value)) ||
        (services.service_code &&
          services.service_code.toLowerCase().includes(value)) ||
        (services.department &&
          services.department.toLowerCase().includes(value))
      );
    });
    setFilteredServicesData(filteredServices);
    // Filter Customers
    const filteredCustomers = CustomersData.filter((customer: any) => {
      return (
        (customer.fullname &&
          customer.fullname.toLowerCase().includes(value)) ||
        (customer.job_title &&
          customer.job_title.toLowerCase().includes(value)) ||
        (customer.phone_no && customer.phone_no.toLowerCase().includes(value))
      );
    });
    setFilteredCustomersData(filteredCustomers);
  };
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : isAdmin ? (
        <div className="layout-container">
          <Col className="sidebar-col" lg={1}>
            <SideBar
              isSkelton={isSkelton}
              setSelectedPage={setSelectedPage}
              selectedPage={selectedPage}
              isAdmin={isAdmin}
            />
          </Col>
          <Col>
            <Header
              setLeftWidth={setLeftWidth}
              leftWidth={leftWidth}
              selectedPage={selectedPage}
              isAdmin={isAdmin}
            />
            {employeeDetailPage ? (
              <EmployeeDetail
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setEmployeeDetailPage={setEmployeeDetailPage}
                selectedEmployee={selectedEmployee}
                setEmployeesData={setEmployeesData}
                setRefetch={setRefetch}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : departmentDetailPage ? (
              <DepartmentDetail
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setDepartmentDetailPage={setDepartmentDetailPage}
                selectedDepartment={selectedDepartment}
                setRefetch={setRefetch}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : listDetailPage ? (
              <ListDetail
                setListDetailPage={setListDetailPage}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                selectedList={selectedList}
                setRefetch={setRefetch}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : plantDetailPage ? (
              <PlantDetail
                setPlantDetailPage={setPlantDetailPage}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                selectedPlant={selectedPlant}
                setRefetch={setRefetch}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : serviceDetailPage ? (
              <ServiceDetail
                setServiceDetailPage={setServiceDetailPage}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                selectedService={selectedService}
                setRefetch={setRefetch}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ) : notificationDetailPage ? (
              <NotificationDetail
                setNotificationDetailPage={setNotificationDetailPage}
                selectedNotification={selectedNotification}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setRefetch={setRefetch}
                setIsLoading={setIsLoading}
              />
            ) : customerDetailPage ? (
              <EmployeeDetail
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setCustomerDetailPage={setCustomerDetailPage}
                selectedCustomerDetail={selectedCustomer}
                isCustomer={isCustomer}
              />
            ) : sectionDetailPage ? (
              <SectionDetail
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setSectionDetailPage={setSectionDetailPage}
                selectedSectionDetail={selectedSection}
                setSelectedSectionDetail={setSelectedSection}
                setIsLoading={setIsLoading}
                setRefetch={setRefetch}
              />
            ) : faqsDetailPage ? (
              <FAQDetail
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setFAQSDetailPage={setFAQSDetailPage}
                selectedFAQ={selectedFAQ}
                setIsLoading={setIsLoading}
                setRefetch={setRefetch}
                isLoading={false}
              />
            ) : mediaCenterDetailPage ? (
              <MediaCenterDetail
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setMediaCenterDetailPage={setMediaCenterDetailPage}
                selectedMediaCenter={selectedMediaCenter}
                setIsLoading={setIsLoading}
                setRefetch={setRefetch}
                isLoading={false}
              />
            ) : homeBannerDetailPage ? (
              <HomeBannerDetail
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                isEditDetail={isEditDetail}
                setIsEditDetail={setIsEditDetail}
                setHomeBannerDetailPage={setHomeBannerDetailPage}
                selectedHomeBanner={selectedHomeBanner}
                setIsLoading={setIsLoading}
                setRefetch={setRefetch}
                isLoading={false}
              />
            ) : (
              <Row>
                <Col
                  lg={
                    selectedPage != "requests"
                      ? isMinimize && selectedPage != "dashboard"
                        ? 11
                        : 9
                      : 12
                  }
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    paddingRight: selectedPage != "requests" ? "" : "30px",
                    height: selectedPage === "requests" ? "auto" : "400px",
                  }}
                >
                  <TableHeader
                    isAdmin={isAdmin}
                    isSkelton={isSkelton}
                    setIsRightSidebar={setIsRightSidebar}
                    isRightSidebar={isRightSidebar}
                    setLeftWidth={setLeftWidth}
                    setSelectedLayout={setSelectedLayout}
                    selectedLayout={selectedLayout}
                    title={
                      selectedPage != "requests"
                        ? selectedMenuItem
                        : "Requesters"
                    }
                    selectedPage={selectedPage}
                    numberOfResults={numberOfResults}
                    handleMenuClick={handleMenuClick}
                    handleSearch={handleSearch}
                  />
                  {isSkelton ? (
                    <CustomTable
                      isSkelton={isSkelton}
                      columns={
                        selectedPage != "requests"
                          ? EmployeeSkeltonColumns
                          : RequestersColumns
                      }
                      data={
                        selectedPage != "requests"
                          ? FilteredEmployeesData
                          : RequestersData
                      }
                    />
                  ) : selectedPage === "requests" ? (
                    <CustomTable
                      isSkelton={isSkelton}
                      columns={RequestersColumns}
                      data={selectedPage === "requests" ? RequestersData : ""}
                    />
                  ) : (
                    <div>
                      <CustomTable
                        isSkelton={false}
                        columns={
                          selectedPage != "requests"
                            ? tableColumn
                            : RequestersColumns
                        }
                        data={
                          selectedPage != "requests"
                            ? tableData
                            : RequestersData
                        }
                        onRow={onRow}
                      />
                    </div>
                  )}
                </Col>
                {selectedPage != "requests" &&
                  (selectedPage != "configuration" ? (
                    <Col lg={3}>
                      <RightSideBar
                        isAdmin={isAdmin}
                        isSkelton={isSkelton}
                        setLeftWidth={setLeftWidth}
                        leftWidth={leftWidth}
                        selectedPage={selectedPage}
                        rightSideAdminCards={rightSideAdminCards}
                      />
                    </Col>
                  ) : (
                    <Col lg={isMinimize ? 1 : 3}>
                      <SystemConfiguration
                        setIsEdit={setIsEdit}
                        setIsEditDetail={setIsEditDetail}
                        setEmployeeDetailPage={setEmployeeDetailPage}
                        setDepartmentDetailPage={setDepartmentDetailPage}
                        setListDetailPage={setListDetailPage}
                        setPlantDetailPage={setPlantDetailPage}
                        setServiceDetailPage={setServiceDetailPage}
                        setNotificationDetailPage={setNotificationDetailPage}
                        setCustomerDetailPage={setCustomerDetailPage}
                        setFAQSDetailPage={setFAQSDetailPage}
                        setMediaCenterDetailPage={setMediaCenterDetailPage}
                        setHomeBannerDetailPage={setHomeBannerDetailPage}
                        setIsCustomer={setIsCustomer}
                        setSectionDetailPage={setSectionDetailPage}
                        isMinimize={isMinimize}
                        setIsMinimize={setIsMinimize}
                        handleConfigurationCardsClick={
                          handleConfigurationCardsClick
                        }
                        counts={counts}
                      />
                    </Col>
                  ))}
              </Row>
            )}
          </Col>
        </div>
      ) : (
        <div className="layout-container">
          <Col className="sidebar-col" lg={1}>
            <SideBar
              isSkelton={isSkelton}
              setSelectedPage={setSelectedPage}
              selectedPage={selectedPage}
            />
          </Col>
          <div
            ref={containerRef}
            id="container"
            className="resizable-container"
          >
            <div style={{ width: leftWidth }} className="resizable-left">
              <Col>
                <Col>
                  <Row>
                    <Header
                      isAdmin={isAdmin}
                      setLeftWidth={setLeftWidth}
                      leftWidth={leftWidth}
                      selectedPage={selectedPage}
                    />
                  </Row>
                  <Row>
                    <EmployeeDashboard
                      setLeftWidth={setLeftWidth}
                      leftWidth={leftWidth}
                      setIsRightSidebar={setIsRightSidebar}
                      isRightSidebar={isRightSidebar}
                      isSkelton={isSkelton}
                      selectedPage={selectedPage}
                    />
                  </Row>
                </Col>
              </Col>
            </div>
            <div className="resizable-divider" onMouseDown={handleResize}></div>
            <div
              style={{ width: `calc(100% - ${leftWidth})` }}
              className="resizable-right"
            >
              {isRightSidebar && (
                <Col>
                  <RightSideBar
                    isAdmin={isAdmin}
                    isSkelton={isSkelton}
                    setLeftWidth={setLeftWidth}
                    leftWidth={leftWidth}
                    selectedPage={selectedPage}
                  />
                </Col>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
