import { Col, Row } from "react-bootstrap";
import "./styles.css";
import { useEffect, useState } from "react";
import axios from "axios";

interface IPlantForm {
  isEdit: boolean;
  plantDetail?: any;
  setPlantDetail?: any;
}
const PlantForm: React.FC<IPlantForm> = ({
  isEdit,
  plantDetail,
  setPlantDetail,
}) => {

  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    const arr: any = [];
    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Departments/list`)
      .then((res) => {
        res.data.data.map((d: any) => {
          arr.push({ name: d?.department?.name, id: d?.department?.id });
        });
        setDepartments(arr);
      });
  }, []);
  const [colors, setColors] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  useEffect(() => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    axios
      .post(
        `https://appadmin.buyinpk.com/portalapis/SystemLists/list?user_id=${userId}`
      )
      .then((res) => {
        const colorList = res?.data?.data?.find(
          (d: any) => d.list_name == "Color"
        );
        const categoryList = res?.data?.data?.find(
          (d: any) => d.list_name == "Category"
        );
        // console.log(colorList);
        const arr = colorList?.list_value?.split(",");
        const arr2 = categoryList?.list_value?.split(",");

        setColors(arr);
        setCategories(arr2);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <Row>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title">Plant ID</p>
            <input
              disabled
              type="text"
              value={plantDetail?.id}
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={5}>
          <div className="inputs-label-col">
            <p className="employee-title">Plant Name</p>
            <input
              type="text"
              disabled={isEdit}
              value={plantDetail?.name}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={5}>
          <div className="inputs-label-col">
            <p className="employee-title">Plant Name (Ar)</p>
            <input
              style={{
                textAlign: "right",
              }}
              type="text"
              disabled={isEdit}
              value={plantDetail?.name_ar}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  name_ar: e.target.value,
                }))
              }
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title">Available Quantity</p>
            <input
              type="text"
              value={plantDetail?.quantity}
              disabled={isEdit}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  quantity: e.target.value,
                }))
              }
              className="employee-form-input"
            />
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">Color</p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              size={1}
              value={plantDetail?.color}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  color: e.target.value,
                }))
              }
            >
              {colors?.map((color: any) => {
                return <option value={color}>{color}</option>;
              })}
            </select>
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">Category</p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              size={1}
              value={plantDetail?.category}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
            >
              {categories?.map((category: any) => {
                return <option value={category}>{category}</option>;
              })}
            </select>
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={4} lg={4}>
          <div className="inputs-label-col">
            <p className="employee-title">Department</p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              id="departments"
              name="departments"
              size={1}
              value={plantDetail?.department_id}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  department_id: e.target.value,
                }))
              }
            >
              {departments.map((depart: any) => {
                return <option value={depart.id}>{depart?.name}</option>;
              })}
            </select>
            {/* <p className="emloyee-input">write...</p> */}
          </div>
        </Col>
        <Col sm={12} md={6} lg={2}>
          <div className="inputs-label-col">
            <p className="employee-title">Plant Height</p>
            <input
              type="text"
              value={plantDetail?.plant_height}
              disabled={isEdit}
              onChange={(e) =>
                setPlantDetail((prev: any) => ({
                  ...prev,
                  plant_height: e.target.value,
                }))
              }
              className="employee-form-input"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PlantForm;
