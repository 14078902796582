import { Card, Col, Container, Row } from "react-bootstrap";
import menu from "../../assets/icons/menu.png";
import chartFill from "../../assets/images/chartFill.png";
import "./styles.css";
import Dropdown from "react-bootstrap/Dropdown";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface IServicesKpi {
  width: number;
  isSkelton: boolean;
}

const ServicesKpi: React.FC<IServicesKpi> = ({ width, isSkelton }) => {
  const chartList = [
    {
      type: "Architecture",
      Color: "#EAB144",
    },
    {
      type: "Social Services",
      Color: "#EC6E46",
    },
    {
      type: "Licensing",
      Color: "#6088C2",
    },
    {
      type: "Transportation",
      Color: "#22A646",
    },
  ];
  return (
    <div>
      {isSkelton ? (
        <Card className="kpiServiceCard">
          <Row>
            <Col sm={6}>
              <SkeletonTheme baseColor="#CDCDCD" height={24} width={24}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
              <SkeletonTheme baseColor="#CDCDCD" height={24} width={157}>
                <div>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <SkeletonTheme baseColor="#CDCDCD" height={118} width={113}>
                <div style={{ borderRadius: "50px" }}>
                  <Skeleton />
                </div>
              </SkeletonTheme>
            </Col>
            <Col sm={6}>
              <SkeletonTheme baseColor="#CDCDCD" height={17} width={119}>
                <div>
                  <Skeleton count={4}/>
                </div>
              </SkeletonTheme>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card className="kpiServiceCard">
          <Row>
            <Col sm={6}>
              <p className="SerKpi">Services KPI</p>
              <Dropdown>
                <Dropdown.Toggle variant="none" id="dropdown-basic">
                  This Week
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">This Week</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">This Month</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">This Year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col sm={6} style={{ textAlign: "end" }}>
              <img src={menu} alt="menu icon" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img src={chartFill} alt="menu icon" />
            </Col>
            <Col sm={6}>
              {chartList.map((list, index) => {
                const colorStyle = {
                  backgroundColor: list.Color,
                  width: "10px",
                  height: "10px",
                  marginRight: "8px",
                  marginBottom: "5px",
                  borderRadius: "50%",
                };
                return (
                  <div key={index} className="ChartListDiv">
                    <p style={colorStyle}></p>
                    <p className="listType">{list.type}</p>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default ServicesKpi;
