import { Card, Col, Row } from "react-bootstrap";

import "./styles.css";
import EmployeeAccount from "../../assets/svg/EmployeeAccount";
import SystemLists from "../../assets/svg/SystemLists";
import Services from "../../assets/svg/Services";
import Plants from "../../assets/svg/Plants";
import Notification from "../../assets/svg/Notification";
import CustomerAccount from "../../assets/svg/CustomerAccount";
import MobileSection from "../../assets/svg/MobileSection";
import Departments from "../../assets/svg/Departments";
import systemLeftArrow from "../../assets/images/systemLeftArrow.png";
import service from "../../assets/icons/service.png";
import notifications from "../../assets/icons/notifications.png";
import customer from "../../assets/icons/customer.png";
import section from "../../assets/icons/section.png";
import serviceSelected from "../../assets/icons/serviceSelected.png";
import sectionSelected from "../../assets/icons/sectionSelected.png";
import customerSelected from "../../assets/icons/customerSelected.png";
import notificationSelected from "../../assets/icons/notificationSelected.png";
import { useState } from "react";

interface ISystemConfiguration {
  setEmployeeDetailPage: any;
  setDepartmentDetailPage: any;
  setListDetailPage: any;
  setPlantDetailPage: any;
  setServiceDetailPage: any;
  setNotificationDetailPage: any;
  setCustomerDetailPage: any;
  setSectionDetailPage: any;
  setIsEdit: any;
  setIsEditDetail?: any;
  isMinimize?: boolean;
  setIsMinimize?: any;
  handleConfigurationCardsClick?: any;
  counts?: any;
  setIsCustomer?: any;
  setFAQSDetailPage?: any;
  setMediaCenterDetailPage?: any;
  setHomeBannerDetailPage?: any;
}
const SystemConfiguration: React.FC<ISystemConfiguration> = ({
  setEmployeeDetailPage,
  setDepartmentDetailPage,
  setListDetailPage,
  setPlantDetailPage,
  setServiceDetailPage,
  setNotificationDetailPage,
  setCustomerDetailPage,
  setSectionDetailPage,
  setIsEdit,
  setIsEditDetail,
  isMinimize,
  setIsMinimize,
  handleConfigurationCardsClick,
  counts,
  setIsCustomer,
  setFAQSDetailPage,
  setMediaCenterDetailPage,
  setHomeBannerDetailPage,
}) => {
  const systemSvgs = [
    {
      heading: "Employees Accounts",
      svg: <EmployeeAccount width={24} height={24} color="#6E4C3E" />,
      svgSelected: <EmployeeAccount width={24} height={24} color="white" />,
      total: counts?.employees,
    },
    {
      heading: "Departments",
      svg: <Departments width={24} height={24} color="#6E4C3E" />,
      svgSelected: <Departments width={24} height={24} color="white" />,
      total: counts?.departments,
    },
    {
      heading: "SystemLists",
      svg: <SystemLists width={24} height={24} color="#6E4C3E" />,
      svgSelected: <SystemLists width={24} height={24} color="white" />,
      total: counts?.lists,
    },
    {
      heading: "Services",
      svg: <img src={service} />,
      svgSelected: <img src={serviceSelected} />,
      total: counts?.services,
    },
    {
      heading: "Plants",
      svg: <Plants width={24} height={24} color="#6E4C3E" />,
      svgSelected: <Plants width={24} height={24} color="white" />,
      total: counts?.plants,
    },
    {
      heading: "Notification",
      svg: <img src={notifications} />,
      svgSelected: <img src={notificationSelected} />,
      total: counts?.notifications,
    },
    {
      heading: "Customer Accounts",
      svg: <img src={customer} />,
      svgSelected: <img src={customerSelected} />,
      total: counts?.customerAccounts,
    },
    {
      heading: "Mobile app sections",
      svg: <img src={section} />,
      svgSelected: <img src={sectionSelected} />,
      total: counts?.mobileAppSections,
    },
    {
      heading: "FAQS",
      svg: <img src={notifications} />,
      svgSelected: <img src={notificationSelected} />,
      total: counts?.faqs,
    },
    {
      heading: "Media Center",
      svg: <img src={notifications} />,
      svgSelected: <img src={notificationSelected} />,
      total: counts?.mediaCenters,
    },
    {
      heading: "Home Banner",
      svg: <img src={notifications} />,
      svgSelected: <img src={notificationSelected} />,
      total: counts?.homeBanners,
    },
  ];
  const [expandedCards, setExpandedCards] = useState<any>([]);

  const handleCardClick = (index: any) => {
    JSON.stringify(localStorage.setItem("selectedSection",index))
    setIsMinimize(false);
    setExpandedCards((prevExpanded: any) =>
      prevExpanded.includes(index)
        ? prevExpanded.filter((item: any) => item !== index)
        : [index]
    );
    handleConfigurationCardsClick(index);
  };

  const handleAddNew = (index: number) => {
    if (index == 0) {
      setIsEdit(false);
      setIsEditDetail(false);
      setEmployeeDetailPage(true);
    } else if (index == 1) {
      setIsEdit(false);
      setIsEditDetail(false);
      setDepartmentDetailPage(true);
    } else if (index == 2) {
      setIsEdit(false);
      setIsEditDetail(false);
      setListDetailPage(true);
    } else if (index == 3) {
      setIsEdit(false);
      setIsEditDetail(false);
      setServiceDetailPage(true);
    } else if (index == 4) {
      setIsEdit(false);
      setIsEditDetail(false);
      setPlantDetailPage(true);
    } else if (index == 5) {
      setIsEdit(false);
      setIsEditDetail(false);
      setNotificationDetailPage(true);
    } else if (index == 6) {
      setIsEdit(false);
      setIsEditDetail(false);
      setCustomerDetailPage(true);
      setIsCustomer(true);
    } else if (index == 7) {
      setIsEdit(false);
      setIsEditDetail(false);
      setSectionDetailPage(true);
    } else if (index == 8) {
      setIsEdit(false);
      setIsEditDetail(false);
      setFAQSDetailPage(true);
    } else if (index == 9) {
      setIsEdit(false);
      setIsEditDetail(false);
      setMediaCenterDetailPage(true);
    } else if (index == 10) {
      setIsEdit(false);
      setIsEditDetail(false);
      setHomeBannerDetailPage(true);
    }
  };
  return (
    <div
      style={{
        background: "white",
        padding: "15px",
        borderRadius: "12px",
        marginRight: "10px",
        // width: isMinimize ? '27%' : ''
      }}
    >
      <Row>
        <Col
          onClick={() => {
            setIsMinimize((prev: boolean) => !prev);
          }}
        >
          <img
            src={systemLeftArrow}
            style={{ transform: isMinimize ? "rotate(180deg)" : "" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {!isMinimize && <p className="sysConfig">System Configurations</p>}
        </Col>
      </Row>

      {systemSvgs.map((tags, index) => {
        const isCardExpanded = expandedCards.includes(index);
        return (
          <div
            style={{
              width: isMinimize ? "72px" : "",
              height: isMinimize ? "72px" : "",
              display: isMinimize ? "flex" : "",
            }}
            key={index}
          >
            <Card
              className={isCardExpanded ? "CardDivSelected" : "CardDiv"}
              onClick={() => handleCardClick(index)}
            >
              <Row>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <p>{isCardExpanded ? tags.svgSelected : tags.svg}</p>
                    {!isMinimize && (
                      <p
                        className={
                          isCardExpanded ? "cardHeadingSelected" : "cardHeading"
                        }
                      >
                        {tags.heading}
                      </p>
                    )}
                  </div>
                  {!isMinimize && (
                    <p
                      className={
                        isCardExpanded ? "totalHeadingSelected" : "totalHeading"
                      }
                    >
                      Total
                    </p>
                  )}
                  {!isMinimize && (
                    <p
                      className={
                        isCardExpanded ? "total-count-selected" : "total-count"
                      }
                    >
                      {tags.total}
                    </p>
                  )}
                </Col>
              </Row>
            </Card>
            {isCardExpanded && !isMinimize && (
              <div
                style={{
                  background: "white",
                  width: "100%",
                  margin: "0px",
                  textAlign: "center",
                  color: "#3E6E4B",
                  fontSize: "20px",
                  fontFamily: "Alexendra",
                  fontWeight: 700,
                  backgroundColor: "#DAF4E1",
                  marginTop: "-10px",
                  paddingTop: "8px",
                  borderRadius: "0px 0px 12px 12px",
                }}
              >
                <Col>
                  <p
                    style={{
                      cursor: "pointer",
                      color: "#3E6E4B",
                      fontSize: 20,
                      fontFamily: "Alexandria",
                      fontWeight: "700",
                      wordWrap: "break-word",
                    }}
                    onClick={() => handleAddNew(index)}
                  >
                    Add New
                  </p>
                </Col>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default SystemConfiguration;
