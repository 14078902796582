import React from "react";
import { Table, Avatar, Tag } from "antd";
import { MoreOutlined, UserOutlined } from "@ant-design/icons";
import type { ColumnsType, TableProps } from "antd/es/table";
import "./style.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface ICustomTable {
  isSkelton: boolean;
  data: any;
  columns: any;
  onRow?: any;
}

interface DataType {
  key: string;
  name: string;
  position: string;
  section: string;
  number: string;
  tags: string;
}

const onChange: TableProps<DataType>["onChange"] = (
  pagination: any,
  filters: any,
  sorter: any,
  extra: any
) => {};

const CustomTable: React.FC<ICustomTable> = ({
  isSkelton,
  columns,
  data,
  onRow,
}) => {
  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        onChange={onChange}
        className="DashboardTable"
        onRow={(record: any, rowIndex) => {
          return {
            onClick: (event) => onRow(record),
          };
        }}
      />
    </div>
  );
};

export default CustomTable;
