import { Card, Col, Container, Row } from 'react-bootstrap';
import menu from '../../assets/icons/menu.png';
import './styles.css';
import Dropdown from 'react-bootstrap/Dropdown';

const CustomerKpi: React.FC<{ width: number }> = ({ width }) => {

  return (
    <div>
      <Card className="kpiCustomerCard">
        <Row>
          <Col sm={9}>
            <p className="SerKpi">New Customers KPI</p>
            <Dropdown>
              <Dropdown.Toggle variant="none" id="dropdown-basic">
                This Week
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">This Week</Dropdown.Item>
                <Dropdown.Item href="#/action-2">This Month</Dropdown.Item>
                <Dropdown.Item href="#/action-3">This Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col sm={3} style={{ textAlign: 'end' }}>
            <img src={menu} alt="menu icon" />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <p className="newReg">Total of new registrations</p>
            <div className="flex">
              <h3 className="priceValue">320</h3>
              <h6 className="priceFlow">
                <span>+</span>102{' '}
              </h6>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CustomerKpi;
