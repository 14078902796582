import { Layout } from "../Layout";

const Head = () => {
  return (
    <>
      <Layout isAdmin={false} isHead={true} />
    </>
  );
};

export default Head;
