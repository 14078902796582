import React from "react";
import "./style.css";
import { Col, Row } from "react-bootstrap";
import CardMenuIcon from "../../assets/svg/CardMenu";

interface IDetailCard {
  width: number;
}

const DetailCard: React.FC<IDetailCard> = ({ width }) => {
  return (
    <>
      <div
        className={
          width > 35
            ? "detail-card-container"
            : "detail-card-container-responsive"
        }
      >
        <Col sm={12} md={6} lg={width > 35 ? 6 : 12} className="testCardStyle">
          <Row>
            <Col xs={6}>
              <p className="cardNo">15123</p>
            </Col>
            <Col xs={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "baseline",
                }}
              >
                <CardMenuIcon width={24} height={24} />
              </div>
            </Col>
          </Row>

          <p className="PersonName">Person name for testing</p>
          <p className="borderline"></p>
          <Row>
            <Col xs={3}>
              <p className="testIdNo">Id No.</p>
            </Col>
            <Col xs={9}>
              <p className="testIdNoList">7841234123412341</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <p className="testIdNo">Subject</p>
            </Col>
            <Col xs={9}>
              <p className="testIdNoDetail">
                I have issues when I try to signup with my old Id
              </p>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6} lg={width > 35 ? 6 : 12} className="testCardStyle">
          <Row>
            <Col xs={6}>
              <p className="cardNo">15123</p>
            </Col>
            <Col xs={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "baseline",
                }}
              >
                <CardMenuIcon width={24} height={24} />
              </div>
            </Col>
          </Row>

          <p className="PersonName">Person name for testing</p>
          <p className="borderline"></p>
          <Row>
            <Col xs={3}>
              <p className="testIdNo">Id No.</p>
            </Col>
            <Col xs={9}>
              <p className="testIdNoList">7841234123412341</p>
            </Col>
          </Row>
          <Row>
            <Col xs={3}>
              <p className="testIdNo">Subject</p>
            </Col>
            <Col xs={9}>
              <p className="testIdNoDetail">
                I have issues when I try to signup with my old Id
              </p>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
};
export default DetailCard;
