import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import logo from "../../assets/images/logo.png";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";
import { Button } from "../../components/Button";
import { TabComponent } from "../../components/Tab";
import { PlantForm } from "../../components/PlantDetailForm";
import DragFiles from "../../components/DragFiles/DragFiles";
import axios from "axios";
import FormData from "form-data";
import { FiUpload } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";
interface IPlantDetail {
  locale?: string;
  setPlantDetailPage?: any;
  isEdit: any;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedPlant?: any;
  setRefetch: any;
  isLoading: boolean;
  setIsLoading: any;
}

const PlantDetail: React.FC<IPlantDetail> = ({
  locale,
  setPlantDetailPage,
  isEdit,
  setIsEdit,
  isEditDetail,
  setIsEditDetail,
  selectedPlant,
  setRefetch,
  isLoading,
  setIsLoading,
}) => {
  // console.log("🚀 ~ selectedPlant:", selectedPlant)
  const [plantDetail, setPlantDetail] = useState(
    isEdit
      ? selectedPlant
      : {
          id: "",
          name: "",
          name_ar: "",
          quantity: "",
          description: "",
          category: "",
          description_ar: "",
          is_active: "",
          color: "",
          plant_height: "",
          department_id: "",
          image: "",
        }
  );
  // console.log("60 MNK_",plantDetail);
  const images = selectedPlant?.images?.map((image: any) => image.image);
  const plantImageLists = selectedPlant?.images;
  // console.log("MNK_",images);
  const [plantImages, setPlantImages] = useState<any>(images);
  const [plantVideo, setPlantVideo] = useState<any>();
  const [plantImageList, setPlantImageList] = useState<any>(plantImageLists);

  const deletePlantImage = (id: any) => {
    Swal.fire({
      title: "Are you sure you want to delete?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "POST",
          url: "https://appadmin.buyinpk.com/portalapis/Plants/deleteListImage",
          headers: {
            // "x-access-token": token,
          },
          data: {
            plant_file_id: id,
          },
        })
          .then((res) => {
            Swal.fire({
              title: res.data.msg,
            });
            setRefetch((prev: boolean) => !prev);
            setPlantDetailPage(false);
          })
          .catch((err) => {
            console.log("error in request", err);
          });
      }
    });
  };
  const tabs = [
    {
      event_key: "images",
      title: "Images",
      component: (
        <DragFiles
          isPlantImages={true}
          plantImages={plantImages}
          setPlantImages={setPlantImages}
          isEdit={isEdit}
          isEditDetail={isEditDetail}
          plantImageList={plantImageList}
          setPlantImageList={setPlantImageList}
          deletePlantImage={deletePlantImage}
        />
      ),
    },
    {
      event_key: "videos",
      title: "Videos",
      component: (
        <DragFiles
          isPlantVideos={true}
          plantVideo={plantVideo}
          setPlantVideo={setPlantVideo}
          isEdit={isEdit}
          isEditDetail={isEditDetail}
        />
      ),
    },
  ];
  const [selectedTab, setSelectedTab] = useState("images");
  const handleTabSelect = (selectedKey: any) => {
    setSelectedTab(selectedKey);
  };

  const handleAddUpdatePlant = () => {
    setIsLoading(true);
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    let data = new FormData();
    data.append("id", plantDetail?.id);
    data.append("name", plantDetail?.name);
    data.append("name_ar", plantDetail?.name_ar);
    data.append("quantity", plantDetail?.quantity);
    data.append("description", plantDetail?.description);
    data.append("category", plantDetail?.category);
    data.append("description_ar", plantDetail?.description_ar);
    data.append("is_active", plantDetail?.is_active);
    data.append("color", plantDetail?.color);
    data.append("plant_height", plantDetail?.plant_height);
    data.append("image", plantDetail?.image);
    data.append("user_id", userId);
    data.append("department_id", plantDetail?.department_id);

    axios
      .post("https://appadmin.buyinpk.com/portalapis/Plants/addEdit", data)
      .then((res) => {
        setIsEdit(true);
        setPlantDetail((prev: any) => ({
          ...prev,
          id: res?.data?.ref,
        }));
        setIsLoading(false);
        setRefetch((prev: boolean) => !prev);
        setPlantDetailPage(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePlantImageVideo = () => {
    setIsLoading(true);
    if (plantImages?.length > 0) {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      let data = new FormData();
      data.append("plant_id", plantDetail?.id);
      data.append("user_id", userId);
      for (let i = 0; i < plantImages?.length; i++) {
        data.append(`images[${i}]`, plantImages[i]);
      }
      // for (let [key, value] of (data as any).entries()) {
      //   console.log(key, value);
      // }
      // console.log(plantImages);
      axios({
        method: "post",
        url: "https://appadmin.buyinpk.com/portalapis/Plants/uploadImages",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          // console.log(res);
          setRefetch((prev: boolean) => !prev);
          setPlantDetailPage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (plantVideo) {
      // console.log(plantVideo);
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      const token = sessionStorage.getItem("token");
      let data = new FormData();
      data.append("plant_id", plantDetail?.id);
      data.append("video_file", plantVideo);
      data.append("user_id", userId);
      data.append("token", token);
      axios({
        method: "post",
        url: "https://appadmin.buyinpk.com/portalapis/Plants/uploadVideo",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          // console.log(res);
          // setRefetch((prev: boolean) => !prev);
          setRefetch((prev: boolean) => !prev);
          setPlantDetailPage(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // setPlantDetailPage(false);
    // setRefetch((prev: boolean) => !prev);
    // setIsLoading(false);
  };
  const [uploadedImage, setUploadedImage] = useState<any>(
    plantDetail?.image ? plantDetail?.image : ""
  );
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles?.forEach((file: File) => {
        if (file) {
          setPlantDetail((prev: any) => ({
            ...prev,
            image: file,
          }));
          const reader = new FileReader();
          reader.onloadend = () => {
            // After reading the file, set the uploaded image in the state
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      });
    },
    [selectedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleDeactivatePlant = () => {
    Swal.fire({
      title: "Are you sure you want to do this?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      if (result.isConfirmed && userId) {
        let data = new FormData();
        data.append("id", selectedPlant?.id);
        data.append("status", selectedPlant?.is_active ? 0 : 1);
        data.append("user_id", userId);

        axios
          .post(
            `https://appadmin.buyinpk.com/portalapis/Plants/updateStatus/${selectedPlant?.id}`,
            data
          )
          .then((res) => {
            setPlantDetail((prev: any) => ({
              ...prev,
              is_active: !prev.is_active,
            }));
            setRefetch((prev: boolean) => !prev);
            setPlantDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };

  const handleDeletePlant = () => {
    Swal.fire({
      title: "Are you sure you want to do this?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      if (result.isConfirmed && userId) {
        axios
          .post(
            `https://appadmin.buyinpk.com/portalapis/Plants/delete/${selectedPlant?.id}?user_id=${userId}`
          )
          .then((res) => {
            // console.log(res);
            setRefetch((prev: boolean) => !prev);
            setPlantDetailPage(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };

  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setPlantDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p className="back-p" onClick={() => setPlantDetailPage(false)}>
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">Add new plant</p>
            </Col>
          )}
          {/* <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={plantDetail?.is_active ? "Edit Details" : "Active"}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (plantDetail?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivatePlant();
                  }
                }}
              />
            ) : (
              ""
            )}
            {
              !isEdit ? (
                <Button
                  title={
                    !isEditDetail
                      ? "Add"
                      : isEdit
                      ? "Edit Details"
                      : "Save changes"
                  }
                  className={"save-changes-btn"}
                  onClick={() => {
                    handleAddUpdatePlant();
                  }}
                />
              ) : (
                ""
              )
              // (
              //   <Button
              //     title={<FormattedMessage id="reset_password" />}
              //     className={"reset-password-btn"}
              //     onClick={() => {}}
              //   />
              // )
            }
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setPlantDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={plantDetail?.is_active ? "Deactivate" : "Delete"}
                className={"deactivate-btn"}
                onClick={() =>
                  plantDetail?.is_active
                    ? handleDeactivatePlant()
                    : handleDeletePlant()
                }
              />
            )}
          </Col> */}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {/* Edit Details Button */}
            {isEdit && (
              <Button
                title="Edit Details"
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  setIsEdit(false);
                  setIsEditDetail(true);
                }}
              />
            )}
            {/* Save Changes Button */}
            {!isEdit && (
              <Button
                title={isEditDetail ? "Save Changes" : "Add"}
                className="save-changes-btn"
                onClick={() => {
                  handleAddUpdatePlant();
                }}
              />
            )}
            {/* Active Button */}
            {(isEdit || isEditDetail) &&
              (plantDetail?.id !== "" || selectedPlant?.id) &&
              (plantImages.length > 0 || plantVideo) && (
                <Button
                  title="Upload Image/Video"
                  className="edit-details-btn"
                  hoverClassName=""
                  onClick={() => {
                    handlePlantImageVideo();
                  }}
                />
              )}
            {/* Active Button */}
            {(isEdit || isEditDetail) && plantDetail?.is_active === false && (
              <Button
                title="Active"
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  handleDeactivatePlant();
                }}
              />
            )}
            {!isEdit && (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setPlantDetailPage(false);
                }}
              />
            )}
            {plantDetail?.is_active && (
              <Button
                title={"Deactivate"}
                className={"deactivate-btn"}
                onClick={() => {
                  if (plantDetail?.is_active) {
                    handleDeactivatePlant();
                  }
                }}
              />
            )}
            {(isEdit || isEditDetail) && (
              <Button
                title={"Delete"}
                className={"deactivate-btn"}
                onClick={() => handleDeletePlant()}
              />
            )}
          </Col>
          {/* <hr /> */}
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">Plant Details</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={3} lg={2}>
            <div
              style={{
                width: "100%",
                // background: "rgba(0, 0, 0, 0.50)",
                height: "170px",
                borderRadius: "8px",
              }}
            >
              {plantDetail?.image ? (
                !isEdit && isEditDetail ? (
                  <div
                    style={{
                      background: `url(${uploadedImage})`,
                      height: "inherit",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                    // {...getInputProps()}
                  >
                    <input
                      type="file"
                      id="file-input"
                      data-testid="file-input"
                      style={{ width: "100%", height: "100%" }}
                      {...getInputProps()}
                    />
                    <label htmlFor="file-input">
                      <span
                        style={{
                          paddingRight: "10px",
                          paddingBottom: "10px",
                          color: "red",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </span>
                    </label>
                  </div>
                ) : (
                  <div>
                    <img className="image-div" src={uploadedImage} alt="Logo" />
                  </div>
                )
              ) : (
                <div
                  style={{
                    width: "100%",

                    height: "170px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(0, 0, 0, 0.50)",
                      height: "inherit",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    {...getRootProps()}
                  >
                    <input
                      type="file"
                      id="file-input"
                      data-testid="file-input"
                      {...getInputProps()}
                    />

                    <FiUpload color="white" size={30} />
                    <label htmlFor="file-input"></label>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col sm={12} md={9} lg={10} className="inputs-col">
            <PlantForm
              isEdit={isEdit}
              plantDetail={plantDetail}
              setPlantDetail={setPlantDetail}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6} lg={12}>
            <div>
              <p className="employee-title">Plant description</p>
              <input
                type="text"
                className="emloyee-input"
                value={plantDetail.description}
                placeholder="Enter Description"
                disabled={isEdit}
                onChange={(e) =>
                  setPlantDetail((prev: any) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                style={{ width: "100%" }}
              />
              {/* <p >write...</p> */}
            </div>
            <div>
              <p className="employee-title">Plant description (Ar)</p>
              <input
                type="text"
                className="emloyee-input"
                value={plantDetail.description_ar}
                disabled={isEdit}
                onChange={(e) =>
                  setPlantDetail((prev: any) => ({
                    ...prev,
                    description_ar: e.target.value,
                  }))
                }
                placeholder="وصف إنتر"
                style={{ width: "100%", textAlign: "right" }}
              />
              {/* <p >write...</p> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <TabComponent
              selectedTab={selectedTab}
              handleTabSelect={handleTabSelect}
              tabs={tabs}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PlantDetail;
