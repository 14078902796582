interface DashboardIconProps {
  width: number;
  height: number;
}

const FilesIcon: React.FC<DashboardIconProps> = ({ width, height }) => {
  return (
    <div className="iconWrapper"> 
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 72" fill="none">
    <g clipPath="url(#clip0_186_3060)">
    <path d="M25 33.5556H47M32.3333 27.4444H28.9111C27.5421 27.4444 26.8576 27.4444 26.3347 27.7109C25.8747 27.9452 25.5008 28.3192 25.2664 28.7791C25 29.302 25 29.9865 25 31.3556V43.0889C25 44.4579 25 45.1424 25.2664 45.6653C25.5008 46.1253 25.8747 46.4992 26.3347 46.7336C26.8576 47 27.5421 47 28.9111 47H43.0889C44.4579 47 45.1424 47 45.6653 46.7336C46.1253 46.4992 46.4992 46.1253 46.7336 45.6653C47 45.1424 47 44.4579 47 43.0889V31.3556C47 29.9865 47 29.302 46.7336 28.7791C46.4992 28.3192 46.1253 27.9452 45.6653 27.7109C45.1424 27.4444 44.4579 27.4444 43.0889 27.4444H39.6667M32.3333 27.4444H39.6667M32.3333 27.4444V26.8333C32.3333 25.8208 31.5125 25 30.5 25C29.4875 25 28.6667 25.8208 28.6667 26.8333V27.4444M39.6667 27.4444V26.8333C39.6667 25.8208 40.4875 25 41.5 25C42.5125 25 43.3333 25.8208 43.3333 26.8333V27.4444" stroke="#9CC5A7" strokeWidth="2" strokeLinecap="round"/>
    </g>
    <defs>
    <clipPath id="clip0_186_3060">
    <rect width="24" height="24" fill="white" transform="translate(24 24)"/>
    </clipPath>
    </defs>
    </svg>
    </div>
  );
}

export default FilesIcon;
