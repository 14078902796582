import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";
import Pdf from "../../assets/images/pdf.png";
import TrashIcon from "../../assets/svg/TrashIcon";
import RightUpArrow from "../../assets/svg/RightUpArrow";
import DownloadIcon from "../../assets/svg/DownloadIcon";
import jsPDF from "jspdf";

const EmployeeAttachment: React.FC = () => {
  const handleDownload = () => {
    const pdf = new jsPDF();
    pdf.text("Reasons of Return!", 10, 10);
    pdf.save("reason_of_return.pdf");
  };
  return (
    <>
      <div>
        <p className="employeeAttachment">Employee Attachments</p>
        <div style={{ display: "flex", gap: "10px" }} className="cardBgColor">
          <div className="pdfImgLogo">
            <img src={Pdf} alt="img tumer" className="imgPlant" />
          </div>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="store"
          >
            <p className="plantName">Reason of return</p>
            <div className="storeDiv">
              <p
                style={{
                  color: "#535353",
                  fontSize: "16px",
                  fontWeight: 600,
                  fontFamily: "Alexandria",
                  marginBottom: "0px",
                }}
              >
                2023-01-01 | 12:00 pm
              </p>
            </div>
            <div className="EmpAttachIcon">
              <RightUpArrow width={50} height={50} />
              <div onClick={handleDownload}>
                <DownloadIcon width={50} height={50} />
              </div>
              <TrashIcon width={50} height={50} />
            </div>
          </div>
          <div
            style={{ color: "#535353", display: "flex", alignItems: "center", marginLeft: '20px' }}
          >
            <p>357 MB</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeAttachment;
