import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./style.css";
import { Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import { Button } from "../../Button";
import { useState } from "react";

interface DataType {
  id: string;
  name: string;
  name_ar: string;
  feild_type: string;
  status: string;
  system_list_id: any;
}

interface IServiceField {
  serviceField: any;
  setServiceFields: any;
  handleFieldsInputChange: any;
  handleDeleteField: any;
  isEdit: boolean;
  systemLists: any;
}
const ServiceFields: React.FC<IServiceField> = ({
  serviceField,
  setServiceFields,
  handleFieldsInputChange,
  handleDeleteField,
  isEdit,
  systemLists,
}) => {
  // console.log("🚀 ~ serviceField:", serviceField);
  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <span style={{ fontSize: "16px", fontWeight: "600", color: "#3E6E4B" }}>
          {text}
        </span>
      ),
      sorter: (a, b) => a.id?.length - b.id?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Field name",
      dataIndex: "name",
      render: (text, record) => (
        <div
          className="avatar-name-container"
          style={{
            display: "flex",
            alignItems: "center",
            wordWrap: "break-word",
          }}
        >
          {/* <Avatar shape="square" size="large" icon={<UserOutlined />} /> */}
          <input
            disabled={isEdit}
            className="employee-form-input"
            placeholder="Enter Field Name"
            type="text"
            value={text}
            onChange={(e) =>
              handleFieldsInputChange(e.target.value, "name", record)
            }
          />
        </div>
      ),

      sorter: (a, b) => a.name?.length - b.name?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Field name (Ar)",
      dataIndex: "name_ar",
      render: (text, record) => (
        <input
          disabled={isEdit}
          className="employee-form-input"
          style={{ textAlign: "right" }}
          type="text"
          placeholder="Enter Field Name Ar"
          value={text}
          onChange={(e) =>
            handleFieldsInputChange(e.target.value, "name_ar", record)
          }
        />
      ),
      sorter: (a, b) => a.name_ar?.length - b.name_ar?.length,
      sortDirections: ["descend"],
    },
    {
      title: "Field type",
      dataIndex: "feild_type",
      render: (text, record) => {
        // console.log("🚀 ~ record:", record)
        return (
          <div style={{ display: "flex", columnGap: "10px" }}>
            <select
              placeholder="Select Field Type"
              disabled={isEdit}
              className="employee-form-input"
              value={text}
              onChange={(e) =>
                handleFieldsInputChange(e.target.value, "feild_type", record)
              }
            >
              <option value={""} disabled selected>
                Select Field Type
              </option>
              <option value={"text"}>Text</option>
              <option value={"dropdown"}>Dropdown</option>
              <option value={"attachment"}>Attachment</option>
            </select>
            {text == "dropdown" && (
              <select
                placeholder="Select System List"
                disabled={isEdit}
                className="employee-form-input"
                value={record?.system_list_id}
                onChange={(e) =>
                  handleFieldsInputChange(
                    e.target.value,
                    "system_list_id",
                    record
                  )
                }
              >
                <option value={""} disabled selected>
                  Select System List
                </option>
                {systemLists?.map((list: any, i: number) => {
                  return (
                    <option key={i} value={list?.id}>
                      {list?.list_value}
                    </option>
                  );
                })}
                {/* <option value={"text"}>Text</option>
              <option value={"checkbox"}>Checkbox</option>
              <option value={"radio"}>Radio</option>
              <option value={"attachment"}>Attachment</option> */}
              </select>
            )}
          </div>
        );
      },
      sorter: (a, b) => a.feild_type?.length - b.feild_type?.length,
      sortDirections: ["descend"],
    },

    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} /> */}
          Status
        </div>
      ),
      dataIndex: "is_active",
      render: (text, record) => (
        <span>
          <Switch
            disabled={isEdit}
            checked={text === "1" || text === "Active"}
            onChange={(checked) =>
              handleFieldsInputChange(
                checked ? "Active" : "De-active",
                "is_active",
                record
              )
            }
          />{" "}
          {/* {text} */}
        </span>
      ),
    },
    {
      title: (
        <div>
          {/* <Switch onChange={() => {}} />  */}
          Required
        </div>
      ),
      dataIndex: "is_required",
      render: (text, record) => (
        <span>
          <Switch
            disabled={isEdit}
            checked={text === "1" || text === "Active"}
            onChange={(checked) =>
              handleFieldsInputChange(
                checked ? "Active" : "De-active",
                "is_required",
                record
              )
            }
          />{" "}
          {/* {text} */}
        </span>
      ),
    },
    {
      title: <MoreOutlined className="MoreOutlined" />,
      dataIndex: "more",
      render: (texst, record) => (
        <DeleteOutlined
          onClick={() => handleDeleteField(record)}
          color="red"
          className="MoreOutlined"
        />
      ),
    },
  ];

  const addNewObject = () => {
    const newObject = {
      id: serviceField?.length + 1,
      // id: 0,
      name: "",
      name_ar: "",
      feild_type: "",
      is_active: "De-active",
      is_required: "De-active",
    };

    // Update the state by spreading the existing data and adding the new object
    setServiceFields([...serviceField, newObject]);
  };
  return (
    <div>
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">Fields</h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "10px 5px" }}>
            <Table
              columns={columns}
              dataSource={serviceField}
              pagination={false}
            />
          </div>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <Button
            className="add_new_field_btn"
            title={"Add New Field"}
            onClick={() => addNewObject()}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceFields;
