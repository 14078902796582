import React from 'react';
interface DashboardIconProps {
  width: number;
  height: number;
  color: string
}
const Plants: React.FC<DashboardIconProps> = ({ width, height, color }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_636_12942)">
    <path d="M12 18.1111V23M9.55556 23H14.4444M11.5852 16.8097C10.4779 17.6265 9.09714 18.1111 7.6 18.1111C3.95492 18.1111 1 15.2383 1 11.6944C1 9.25057 2.40527 7.12579 4.47305 6.04224C5.1964 5.66319 5.79989 5.0669 6.19873 4.35427C7.31701 2.35613 9.49561 1 12 1C14.5044 1 16.683 2.35613 17.8013 4.35427C18.2001 5.0669 18.8036 5.66319 19.5269 6.04224C21.5947 7.12579 23 9.25057 23 11.6944C23 15.2383 20.0451 18.1111 16.4 18.1111C14.9029 18.1111 13.5221 17.6265 12.4148 16.8097C12.1694 16.6288 11.8306 16.6288 11.5852 16.8097Z" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    </g>
    <defs>
    <clipPath id="clip0_636_12942">
    <rect width="24" height="24" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}
export default Plants;
