import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./style.css";
import React from "react";

interface ITermAndCondtion {
  serviceDetail?: any;
  setServiceDetail?: any;
  isEdit?: boolean;
}
const TermAndCondition: React.FC<ITermAndCondtion> = ({
  isEdit,
  serviceDetail,
  setServiceDetail,
}) => {
  return (
    <div>
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">
            <FormattedMessage id="details" />
          </h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "10px 5px" }}>
            <p className="employee-title">Term and Conditions</p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.terms_and_conditions}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  terms_and_conditions: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
      </Row>
      <Row className="personal-details-row2" style={{ paddingBottom: "10px" }}>
        <Col sm={12} md={12} lg={12}>
          <div style={{ padding: "0px 5px" }}>
            <p className="employee-title">Term and Conditions (Ar)</p>
            <input
              disabled={isEdit}
              type="text"
              value={serviceDetail?.terms_and_conditions_ar}
              onChange={(e) => {
                setServiceDetail((prev: any) => ({
                  ...prev,
                  terms_and_conditions_ar: e.target.value,
                }));
              }}
              className="employee-form-input"
            />
            {/* <p className="employee-form-input">write...</p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TermAndCondition;
