import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
// import { useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";
// import { PersonalDetails ,WorkInformation} from "../../components/EmpDetailTabs/index";
import { ListDetailForm } from "../../components/ListDetail";
import { Button } from "../../components/Button";
import { useState } from "react";
import axios from "axios";
import qs from "qs";
import { MediaCenterDetailForm } from "../../components/MediaCenterDetail";
import HomeBannerDetailForm from "../../components/HomeBannerDetail/HomeBannerDetailForm";
const FormData = require("form-data");
interface IHomeBannerDetail {
  locale?: string;
  setHomeBannerDetailPage: any;
  isEdit: any;
  setIsEdit: any;
  isEditDetail: any;
  setIsEditDetail: any;
  selectedHomeBanner: any;
  setRefetch: any;
  isLoading: boolean;
  setIsLoading: any;
}

const HomeBannerDetail: React.FC<IHomeBannerDetail> = ({
  locale,
  setHomeBannerDetailPage,
  isEdit,
  isEditDetail,
  setIsEdit,
  setIsEditDetail,
  selectedHomeBanner,
  setRefetch,
  isLoading,
  setIsLoading,
}) => {
  const [HomeBannerDetail, setHomeBannerDetail] = useState(
    isEdit
      ? selectedHomeBanner
      : {
          id: "",
          title: "",
          title_ar: "",
          description: "",
          description_ar: "",
          image: "",
          media_type: "",
          is_active: "",
        }
  );

  const handleAddUpdateHomeBanner = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    setIsLoading(true);
    let data = new FormData();
    data.append("id", HomeBannerDetail?.id);
    data.append("title", HomeBannerDetail?.title);
    data.append("title_ar", HomeBannerDetail?.title_ar);
    data.append("media_type", "HOME");
    data.append("is_active", HomeBannerDetail?.id ? HomeBannerDetail?.is_active : true);
    data.append("user_id", userId);
    data.append("description", HomeBannerDetail?.description);
    data.append("description_ar", HomeBannerDetail?.description_ar);
    data.append("image", HomeBannerDetail?.image);
    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/MediaCenters/addEditImageArticle",
        data
      )
      .then((res) => {
        setIsEdit(true);
        setHomeBannerDetail((prev: any) => ({
          ...prev,
          id: res?.data?.ref,
        }));
        setRefetch((prev: any) => !prev);
        setHomeBannerDetailPage(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDeactivateHomeBanner = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    const token = sessionStorage.getItem("token");

    let data = qs.stringify({
      media_center_id: HomeBannerDetail?.id,
      status: HomeBannerDetail?.is_active ? 0 : 1,
      user_id: userId,
      token: token,
    });

    axios
      .post("https://appadmin.buyinpk.com/portalapis/HomeBanners/updateStatus", data)
      .then((res) => {
        setHomeBannerDetail((prev: any) => ({
          ...prev,
          is_active: !prev.is_active,
        }));
        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteHomeBanner = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    axios
      .post(
        `https://appadmin.buyinpk.com/portalapis/SystemHomeBanners/delete/${selectedHomeBanner?.id}?user_id=${userId}`
      )
      .then((res) => {
        console.log(res);
        setRefetch((prev: boolean) => !prev);
        setHomeBannerDetailPage(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => setHomeBannerDetailPage(false)}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p
                className="back-p"
                onClick={() => setHomeBannerDetailPage(false)}
              >
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">Add new Banner</p>
            </Col>
          )}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={HomeBannerDetail?.is_active ? "Edit Details" : "Active"}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (HomeBannerDetail?.is_active) {
                    setIsEdit(false);
                    setIsEditDetail(true);
                  } else {
                    handleDeactivateHomeBanner();
                  }
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={
                  !isEditDetail
                    ? "Add"
                    : isEdit
                    ? "Edit Details"
                    : "Save changes"
                }
                className={"save-changes-btn"}
                onClick={() => {
                  handleAddUpdateHomeBanner();
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  setIsEdit(true);
                  setHomeBannerDetailPage(false);
                }}
              />
            ) : (
              <Button
                title={HomeBannerDetail?.is_active ? "Deactivate" : "Delete"}
                className={"deactivate-btn"}
                onClick={() =>
                  HomeBannerDetail?.is_active
                    ? handleDeactivateHomeBanner()
                    : handleDeleteHomeBanner()
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">Home Banner Details</p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={12} lg={12} className="inputs-col">
            <HomeBannerDetailForm
              isEdit={isEdit}
              isEditDetail={isEditDetail}
              selectedHomeBanner={HomeBannerDetail}
              setHomeBannerDetail={setHomeBannerDetail}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomeBannerDetail;
