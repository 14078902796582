function QuestionIcon() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 44 44" fill="none">
<path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#FBF1ED"/>
<g clipPath="url(#clip0_0_3748)">
<path d="M22 28V28.01M18 20C18 17.7909 19.7909 16 22 16C24.2091 16 26 17.7909 26 20C26 21.8675 24.7202 23.4361 22.9899 23.8766C22.4547 24.0128 22 24.4477 22 25M33 22C33 28.0751 28.0751 33 22 33C15.9249 33 11 28.0751 11 22C11 15.9249 15.9249 11 22 11C28.0751 11 33 15.9249 33 22Z" stroke="#6E4C3E" strokeWidth="2" strokeLinecap="round"/>
</g>
<defs>
<clipPath id="clip0_0_3748">
<rect width="24" height="24" fill="white" transform="translate(10 10)"/>
</clipPath>
</defs>
</svg>
  );
}

export default QuestionIcon;
