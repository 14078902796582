import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import deptHer from "../../../assets/images/Department Hierarchy.png";
import "./style.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Select } from "antd";

interface IWorkInformation {
  isEdit?: boolean;
  employeeDetail?: any;
  setEmployeeDetail?: any;
}
const WorkInformation: React.FC<IWorkInformation> = ({
  isEdit,
  employeeDetail,
  setEmployeeDetail,
}) => {
  // console.log("employeeDetail", employeeDetail);
  const [departments, setDepartments] = useState([]);
  const [types, setTypes] = useState([]);
  const [positions, setPositions] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [manager, setManager] = useState();

  // const handleChange = (selectedOption:any) => {
  //   console.log("Selected Option: ", selectedOption);
  //   setEmployeeDetail((prev:any) => ({
  //     ...prev,
  //     position_id: selectedOption,
  //   }));
  // };

  // const selectedValues = employeeDetail?.positions
  //   .filter((pos:any) =>
  //     positions.some((position:any) => position.id === pos.designation_id)
  //   )
  //   .map((pos:any) => pos.designation_id);

  useEffect(() => {
    let arr: any = [];
    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Departments/list`)
      .then((res) => {
        res.data.data.map((d: any) => {
          arr.push({
            name: d?.department?.name,
            id: d?.department?.id,
            manager: d?.department?.employee?.fullname,
          });
        });
        setDepartments(arr);
        const filtered = arr?.filter(
          (a: any) => a.id == employeeDetail?.department_id
        );
        setManager(filtered[0]?.manager);
      })
      .catch((err) => {
        console.log(err);
      });

    let arr2: any = [];

    axios
      .post(`https://appadmin.buyinpk.com/portalapis/EmployeeTypes/list`)
      .then((res) => {
        res?.data?.data?.map((d: any) => {
          arr2.push({
            name: d?.name,
            id: d?.id,
          });
        });
        setTypes(arr2);
      })
      .catch((err) => {
        console.log(err);
      });
    let arr3: any = [];
    const userId: any = sessionStorage.getItem("userDetails");
    axios
      .post(
        `https://appadmin.buyinpk.com/portalapis/Designations/getAll?user_id=${userId?.id}`
      )
      .then((res) => {
        res?.data?.data?.map((d: any) => {
          arr3.push({
            name: d?.name,
            id: d?.id,
          });
        });
        setPositions(arr3);
      })
      .catch((err) => {
        console.log(err);
      });

    let arr4: any = [];

    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Employees/list`)
      .then((res) => {
        res?.data?.data?.map((d: any) => {
          arr4.push({
            name: d?.employee?.fullname,
            id: d?.employee?.id,
          });
        });
        setEmployees(arr4);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="setWorkBgColor">
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">
            <FormattedMessage id="details" />
          </h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
          <p className="history">
            <FormattedMessage id="history" />
          </p>
        </Col>
      </Row>
      <Row className="personal-details-row2 workbg">
        <Col sm={12} md={8}>
          <Row>
            <Col sm={12} md={6} xl={6}>
              <div className="inputs-label-col">
                <p className="employee-title">
                  <FormattedMessage id="department" />
                </p>
                <select
                  disabled
                  className="employee-form-input"
                  id="departments"
                  name="departments"
                  value={employeeDetail?.department_id}
                  size={1}
                >
                  {departments?.map((depart: any, index) => {
                    return (
                      <option key={depart.id} value={depart.id}>
                        {depart?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <div className="inputs-label-col">
                <p className="employee-title">Department Manager</p>
                <input
                  disabled
                  type="text"
                  // value={employeeDetail?.department_manager[0]?.name}
                  value={manager}
                  className="employee-form-input"
                />
              </div>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <div className="inputs-label-col">
                <p className="employee-title">
                  <FormattedMessage id="Employee Type" />
                </p>
                <select
                  disabled={isEdit}
                  className="employee-form-input"
                  size={1}
                  value={employeeDetail?.employee_type_id}
                  onChange={(e) => {
                    setEmployeeDetail((prev: any) => ({
                      ...prev,
                      employee_type_id: e.target.value,
                    }));
                  }}
                >
                  <option>Select </option>
                  {types.map((type: any, index) => {
                    return (
                      <option key={index} value={type.id}>
                        {type?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <div className="inputs-label-col">
                <p className="employee-title">
                  <FormattedMessage id="Job Title" />
                </p>
                <select
                  disabled={isEdit}
                  className="employee-form-input"
                  size={1}
                  value={employeeDetail?.job_title_id}
                  onChange={(e) => {
                    setEmployeeDetail((prev: any) => ({
                      ...prev,
                      job_title_id: e.target.value,
                    }));
                  }}
                >
                  <option>Select </option>
                  {positions?.map((type: any, index) => {
                    return (
                      <option key={index} value={type.id}>
                        {type?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <div className="inputs-label-col">
                <p className="employee-title">
                  <FormattedMessage id="Position" />
                </p>
                
                <Select
                  mode="multiple"
                  allowClear
                  style={{
                    width: "100%",
                    height: "auto",
                    background: "#F6F6F6",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                  placeholder="select Position"
                  disabled={isEdit}
                  // className="employee-form-input"
                  value={employeeDetail?.position_id}
                  onChange={(selectedOption) => {
                    setEmployeeDetail((prev: any) => ({
                      ...prev,
                      position_id: selectedOption,
                    }));
                  }}
                  options={positions?.map((position: any) => ({
                    value: position.id,
                    label: position.name,
                  }))}
                />
              </div>
            </Col>
            <Col sm={12} md={6} xl={6}>
              <div className="inputs-label-col">
                <p className="employee-title">
                  <FormattedMessage id="Direct Manager" />
                </p>
                <select
                  disabled={isEdit}
                  className="employee-form-input"
                  size={1}
                  placeholder="Select Direct Manager"
                  value={employeeDetail?.line_manager_id}
                  onChange={(e) => {
                    setEmployeeDetail((prev: any) => ({
                      ...prev,
                      line_manager_id: e.target.value,
                    }));
                  }}
                >
                  {employees.map((employee: any, index) => {
                    return (
                      <option key={index} value={employee.id}>
                        {employee?.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Col>

            <Col sm={12}>
              <div className="inputs-label-col">
                <p className="employee-title">
                  <FormattedMessage id="Can sign on behalf of manager" />
                </p>
                <select
                  disabled={isEdit}
                  className="employee-form-input"
                  size={1}
                  value={
                    employeeDetail?.can_sign_as_manager
                      ? employeeDetail?.can_sign_as_manager
                      : 1
                  }
                  onChange={(e) => {
                    setEmployeeDetail((prev: any) => ({
                      ...prev,
                      can_sign_as_manager: e.target.value,
                    }));
                  }}
                >
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                {/* <p className="emloyee-input">Yes...</p> */}
              </div>
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={3}>
          <div className="treeDiv">
            <img src={deptHer} alt="deptHer" />
            {/* <img src={deptHer} alt="deptHer" /> */}
            {/* <p className="employee-title">
            Department Hierarchy
          </p> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WorkInformation;
