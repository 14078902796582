import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import "./style.css";
import { useState } from "react";
import { Button } from "../Button";
import axios from "axios";
import FormData from "form-data";
import { Loading } from "../../pages/Loading";

interface ILoginCard {
  locale: string;
  setIsLoading: any;
}
const LoginCard: React.FC<ILoginCard> = ({ locale, setIsLoading }) => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });
  const handleLoginButtonClick = async () => {
    setIsLoading(true);
    let data = new FormData();
    data.append("username", loginDetails?.username);
    data.append("password", loginDetails?.password);

    axios
      .post("https://appadmin.buyinpk.com/portalapis/Admin/verify", data)
      .then((response: any) => {
        // console.log(response);

        if (response?.data?.username == "admin") {
          sessionStorage.setItem("token", response?.data?.token);
          sessionStorage.setItem("userDetails", JSON.stringify(response?.data));
          setIsLoading(false);
          localStorage.setItem("selectedSection", "employee");
          navigate("/");
        } else if (response?.data?.username == "head") {
          setIsLoading(false);
          navigate("/head");
        } else if (response?.data?.username == "employee") {
          setIsLoading(false);
          navigate("/head");
        } else {
          setIsLoading(false);
          alert("Invalid Username or Password");
        }
      });
  };
  return (
    <>
      <div className="login-card-inner-div">
        <p className="welcome_message">
          <FormattedMessage id="welcome_back" />
        </p>
        <p className="login_message">
          <FormattedMessage id="login" />
        </p>

        <p className="username-p">
          <FormattedMessage id="user_name" />
        </p>
        <input
          type="text"
          className="username-input"
          onChange={(e) => {
            setLoginDetails((prev) => ({ ...prev, username: e.target.value }));
          }}
        />

        <p className="password-p">
          <FormattedMessage id="password" />
        </p>
        <input
          type="password"
          className="username-input width-auto"
          onChange={(e) => {
            setLoginDetails((prev) => ({ ...prev, password: e.target.value }));
          }}
        />

        <div className="remember-me-div">
          <div className="remember-me-inner-div">
            <input
              type="checkbox"
              className="remember-me-input"
              // onChange={(e) => {
              //   setLoginDetails((prev) => ({
              //     ...prev,
              //     rememberMe: e.target.checked,
              //   }));
              // }}
            />
            <p className="remember-me-p">
              <FormattedMessage id="remember_me" />
            </p>
          </div>
          <Button
            title={<FormattedMessage id="login" />}
            className={locale == "en" ? "login-btn" : "login-btn-arabic"}
            onClick={handleLoginButtonClick}
          />
        </div>
        <p className="reset-password-p">
          <FormattedMessage id="forget_your_password" />{" "}
          <a>
            <span className="reset-password-span">
              <FormattedMessage id="reset_password" />
            </span>
          </a>{" "}
          <FormattedMessage id="or_call" />{" "}
          <a href="">
            <span className="helpdesk-span">
              <FormattedMessage id="helpdesk" />
            </span>
          </a>
        </p>
      </div>
    </>
  );
};

export default LoginCard;
