import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import "./style.css";
import {
  PersonalDetails,
  WorkInformation,
} from "../../components/EmpDetailTabs/index";
import { EmployeeForm } from "../../components/EmployeeDetailForm";
import { Button } from "../../components/Button";
import { TabComponent } from "../../components/Tab";
import axios from "axios";
import Permissions from "../../components/EmpDetailTabs/Permissions/Permissions";
import { useDropzone } from "react-dropzone";
import { FiUpload } from "react-icons/fi";
import Swal from "sweetalert2";

import qs from "qs";

interface IEmployeeDetail {
  locale?: string;
  setEmployeeDetailPage?: any;
  setCustomerDetailPage?: any;
  isEdit?: boolean;
  isEditDetail?: boolean;
  setIsEditDetail?: any;
  setIsEdit?: any;
  isCustomer?: boolean;
  selectedEmployee?: any;
  setEmployeesData?: any;
  setRefetch?: any;
  isLoading?: boolean;
  setIsLoading?: any;
  selectedCustomerDetail?: any;
}

const EmployeeDetail: React.FC<IEmployeeDetail> = ({
  locale,
  setEmployeeDetailPage,
  setCustomerDetailPage,
  isEdit,
  isEditDetail,
  setIsEditDetail,
  setIsEdit,
  isCustomer,
  selectedEmployee,
  setEmployeesData,
  setRefetch,
  isLoading,
  setIsLoading,
  selectedCustomerDetail,
}) => {
  console.log("first", selectedEmployee);
  const [employeeDetail, setEmployeeDetail] = useState(
    isEdit
      ? isCustomer
        ? selectedCustomerDetail
        : selectedEmployee
      : {
          id: "",
          fullname: "",
          fullname_ar: "",
          employee_type_id: "",
          designation_id: "",
          emiratesid: "",
          country_id: "",
          nationality: "",
          mobile: "",
          email: "",
          pasword: "",
          is_active: "",
          department_id: "",
          department_manager: "",
          job_title_id: "",
          address: "",
          mother_name: "",
          passport_no: "",
          home_email: "",
          home_mobile: "",
          office_no: "",
          image: "",
          union_no: "",
          current_status: "",
          position_id: "",
          line_manager_id: "",
          can_sign_as_manager: "",
          permissions: [],
        }
  );
  console.log("detail", employeeDetail);
  const handleResetPassword = () => {
    Swal.fire({
      title: "You want to reset this employee's password?",
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      customClass: {
        actions: "my-actions",
        confirmButton: "order-2",
        denyButton: "order-3",
      },
    }).then((result) => {
      const token = sessionStorage.getItem("token");
      if (result.isConfirmed && token) {
        const data = {
          employee_id: employeeDetail?.id,
          token: token,
        };
        axios
          .post(
            `https://appadmin.buyinpk.com/portalapis/Employees/resetPassword`,
            data
          )
          .then((res) => {
            // console.log("ResetPassword", res?.data);
            if (res?.data?.status === true) {
              Swal.fire(res?.data?.msg);
            }
          })
          .catch((err) => {
            console.log("ResetPassword", err);
          });
      } else if (result.isDenied) {
        // Swal.fire("Changes are not saved");
      }
    });
  };

  const tabs = [
    {
      event_key: "personal-details",
      title: "personal_details",
      component: (
        <PersonalDetails
          isEdit={isEdit}
          employeeDetail={employeeDetail}
          setEmployeeDetail={setEmployeeDetail}
        />
      ),
    },
    {
      event_key: "documents",
      title: "Documents",
      component: <WorkInformation />,
    },
    {
      event_key: "work-information",
      title: "work_information",
      component: (
        <WorkInformation
          isEdit={isEdit}
          employeeDetail={employeeDetail}
          setEmployeeDetail={setEmployeeDetail}
        />
      ),
    },
    {
      event_key: "permissions",
      title: "permissions",
      component: (
        <Permissions
          isEdit={isEdit}
          employeeDetail={employeeDetail}
          setEmployeeDetail={setEmployeeDetail}
        />
      ),
    },
    {
      event_key: "financial-details",
      title: "financial_details",
      component: <PersonalDetails />,
    },
    {
      event_key: "education",
      title: "educations",
      component: <PersonalDetails />,
    },
    { event_key: "leaves", title: "leaves", component: <PersonalDetails /> },
  ];
  const [selectedTab, setSelectedTab] = useState("personal-details");
  const handleTabSelect = (selectedKey: any) => {
    setSelectedTab(selectedKey);
  };

  const handleAddUpdateEmployee = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    // console.log(employeeDetail, "employee_Detail");
    setIsLoading(true);
    let data = JSON.stringify({
      info: {
        id: employeeDetail?.id,
        fullname: employeeDetail?.fullname,
        fullname_ar: employeeDetail?.fullname_ar,
        email: employeeDetail?.email,
        pasword: employeeDetail?.pasword,
        is_active: 1,
        department_id: employeeDetail?.department_id,
        current_status: "Online",
        mobile: employeeDetail?.mobile,
        user_id: userId,
      },
      details: {
        address: employeeDetail?.address,
        emiratesid: employeeDetail?.emiratesid,
        country_id: employeeDetail?.country_id,
        mother_name: employeeDetail?.mother_name,
        passport_no: employeeDetail?.passport_no,
        home_mobile: employeeDetail?.home_mobile,
        office_no: employeeDetail?.office_no,
        home_email: employeeDetail?.home_email,
        union_no: employeeDetail?.union_no,
      },
      workinfo: {
        employee_type_id: employeeDetail?.employee_type_id,
        designation_id: employeeDetail?.job_title_id,
        job_title_id: employeeDetail?.job_title_id,
        position_id: employeeDetail?.position_id,
        line_manager_id: employeeDetail?.line_manager_id,
        can_sign_as_manager: employeeDetail?.can_sign_as_manager,
      },
      permissions: employeeDetail.permissions,
    });
    // console.log("data",data)
    axios
      .post("https://appadmin.buyinpk.com/portalapis/Employees/addEdit", data)
      .then((response: any) => {
        // console.log(response?.data);
        if (response?.data?.status === false) {
          alert(response?.data?.msg);
          setIsLoading(false);
          setEmployeeDetailPage(false);
        } else {
          setEmployeeDetail((prev: any) => ({
            ...prev,
            id: response?.data?.ref,
          }));
          setIsEdit(true);
          setIsLoading(false);
          setEmployeeDetailPage(false);
          setRefetch((prev: any) => !prev);
        }
      })
      .catch((err: any) => {
        alert(err);
        setIsLoading(false);
        // setIsEdit(true);
      });
  };
  const [uploadedImage, setUploadedImage] = useState<any>(
    employeeDetail?.image ? employeeDetail?.image : ""
  );

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
      acceptedFiles?.forEach((file: File) => {
        if (file) {
          let data = new FormData();
          data.append("id", "");
          data.append("image", file);
          data.append("user_id", userId);
          axios
            .post(
              "https://appadmin.buyinpk.com/portalapis/Employees/changeImage",
              data
            )
            .then((res: any) => {
              // console.log(res);

              setEmployeeDetail((prev: any) => ({
                ...prev,
                image: res?.data?.img,
              }));
            })
            .catch((err) => {
              console.log(err);
            });
          const reader = new FileReader();
          reader.onloadend = () => {
            setUploadedImage(reader.result);
          };
          reader.readAsDataURL(file);
        }
      });
    },
    [selectedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const handleDeactivateEmployee = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;

    let data = qs.stringify({
      employee_id: selectedEmployee?.id,
      status: selectedEmployee?.is_active ? "0" : "1",
      user_id: userId,
    });

    axios
      .post(
        "https://appadmin.buyinpk.com/portalapis/Employees/updateStatus",
        data
      )
      .then((res) => {
        setEmployeeDetail((prev: any) => ({
          ...prev,
          is_active: !prev.is_active,
        }));
        setEmployeeDetailPage(false);
        setRefetch((prev: boolean) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteEmployee = () => {
    const userId = JSON.parse(sessionStorage.getItem("userDetails") + "")?.id;
    axios
      .post(
        `https://appadmin.buyinpk.com/portalapis/Employees/delete/${selectedEmployee?.id}?user_id=${userId}`
      )
      .then((res) => {
        // console.log(res);
        setRefetch((prev: boolean) => !prev);
        setEmployeeDetailPage(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Container
        className="employee-detail-container"
        style={{ minHeight: "100vh", backgroundColor: "white" }}
      >
        <Row className="header-row">
          {isEdit || isEditDetail ? (
            <Col sm={4} md={6} lg={6} className="back-col">
              <div
                className="redBackBg"
                onClick={() => {
                  isCustomer
                    ? setCustomerDetailPage(false)
                    : setEmployeeDetailPage(false);
                }}
              >
                <IoIosArrowBack color="red" size={20} />
              </div>
              <p
                className="back-p"
                onClick={() => {
                  isCustomer
                    ? setCustomerDetailPage(false)
                    : setEmployeeDetailPage(false);
                }}
              >
                <FormattedMessage id="back" />
              </p>
            </Col>
          ) : (
            <Col sm={4} md={6} lg={6} className="back-col">
              <p className="add_new_title">
                Add new {isCustomer ? "customer" : "employee"}
              </p>
            </Col>
          )}
          <Col sm={4} md={6} lg={6} className="btns-col">
            {isEdit ? (
              <Button
                title={employeeDetail?.is_active ? "Edit Details" : "Active"}
                className="edit-details-btn"
                hoverClassName=""
                onClick={() => {
                  if (!isCustomer) {
                    if (employeeDetail?.is_active) {
                      setIsEdit(false);
                      setIsEditDetail(true);
                    } else {
                      handleDeactivateEmployee();
                    }
                  }
                }}
              />
            ) : (
              ""
            )}
            {!isEdit ? (
              <Button
                title={
                  !isEditDetail
                    ? "Add"
                    : isEdit
                    ? "Edit Details"
                    : "Save changes"
                }
                className={"save-changes-btn"}
                onClick={() => {
                  if (!isCustomer) {
                    handleAddUpdateEmployee();
                  }
                }}
              />
            ) : (
              <Button
                title={<FormattedMessage id="reset_password" />}
                className={"reset-password-btn"}
                onClick={handleResetPassword}
              />
            )}
            {!isEdit ? (
              <Button
                title={"Cancel"}
                className="deactivate-btn"
                onClick={() => {
                  if (!isCustomer) {
                    setEmployeeDetailPage(false);
                  } else {
                    setCustomerDetailPage(false);
                  }
                }}
              />
            ) : (
              <Button
                title={employeeDetail?.is_active ? "Deactivate" : "Delete"}
                className={"deactivate-btn"}
                onClick={() => {
                  if (!isCustomer) {
                    employeeDetail?.is_active
                      ? handleDeactivateEmployee()
                      : handleDeleteEmployee();
                  }
                }}
              />
            )}
          </Col>
          {/* <hr /> */}
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <p className="employee-details-p">
              {isCustomer ? (
                "Customer details"
              ) : (
                <FormattedMessage id="employee_details" />
              )}
            </p>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm={12} md={3} lg={2}>
            <div
              style={{
                width: "100%",
                // background: "rgba(0, 0, 0, 0.50)",
                height: "170px",
                borderRadius: "8px",
              }}
            >
              {employeeDetail?.image ? (
                !isEdit && isEditDetail ? (
                  <div
                    style={{
                      background: `url(${uploadedImage})`,
                      height: "inherit",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                    // {...getInputProps()}
                  >
                    <input
                      type="file"
                      id="file-input"
                      data-testid="file-input"
                      style={{ width: "100%", height: "100%" }}
                      {...getInputProps()}
                    />
                    <label htmlFor="file-input"></label>
                    <span
                      style={{
                        paddingRight: "10px",
                        paddingBottom: "10px",
                        color: "white",
                      }}
                    >
                      Edit
                    </span>
                  </div>
                ) : (
                  <div>
                    <img className="image-div" src={uploadedImage} alt="Logo" />
                  </div>
                )
              ) : (
                <div
                  style={{
                    width: "100%",

                    height: "170px",
                    borderRadius: "8px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(0, 0, 0, 0.50)",
                      height: "inherit",
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    {...getRootProps()}
                  >
                    <input
                      type="file"
                      id="file-input"
                      data-testid="file-input"
                      {...getInputProps()}
                    />

                    <FiUpload color="white" size={30} />
                    <label htmlFor="file-input"></label>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col sm={12} md={9} lg={10} className="inputs-col">
            <EmployeeForm
              isCustomer={isCustomer}
              isEdit={isEdit}
              employeeDetail={employeeDetail}
              setEmployeeDetail={setEmployeeDetail}
            />
          </Col>
        </Row>
        {!isCustomer && (
          <Row>
            <Col sm={12} md={12} lg={12} className="tabs-col">
              <TabComponent
                selectedTab={selectedTab}
                handleTabSelect={handleTabSelect}
                tabs={tabs}
              />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default EmployeeDetail;
