import { EmployeeDashboard } from "../EmployeeDashboard";
import { Layout } from "../Layout";

const Employee = () => {
  return (
    <>
      <Layout isAdmin={false}/>
        {/* <EmployeeDashboard /> */}
      {/* </Layout> */}
    </>
  );
};

export default Employee;
