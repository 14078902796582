import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {

  const storedUserDetails = sessionStorage.getItem("userDetails");

  const isAuthenticated = storedUserDetails
    ? JSON.parse(storedUserDetails)
    : null; // You can modify this logic

  return isAuthenticated?.token ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
