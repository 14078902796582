import { Col } from "react-bootstrap";
import UploadIcon from "../../assets/images/UploadIcon.png";
import { FiUploadCloud, FiTrash2 } from "react-icons/fi";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Swal from "sweetalert2";

interface IDragFiles {
  isPlantImages?: boolean;
  isPlantVideos?: boolean;
  plantImages?: any;
  setPlantImages?: any;
  plantVideo?: any;
  setPlantVideo?: any;
  isEdit?: any;
  isEditDetail?: any;
  plantImageList?: any;
  setPlantImageList?: any;
  deletePlantImage?: any;
}
const DragFiles: React.FC<IDragFiles> = ({
  isPlantImages,
  isPlantVideos,
  plantImages,
  setPlantImages,
  setPlantVideo,
  isEdit,
  isEditDetail,
  plantImageList,
  setPlantImageList,
  deletePlantImage,
}) => {
  // console.log(plantImages, "plant imgaes");
  // const [selectedFiles, setSelectedFiles] = useState<any>([]);
  // const [uploadedImages, setUploadedImages] = useState<any>(
  //   plantImages?.length > 0 ? plantImages : []
  // );

  // useEffect(() => {
  //   setUploadedImages(plantImages?.length > 0 ? plantImages : []);
  // }, [plantImages]);

  // const onDrop = useCallback(
  //   (acceptedFiles: any) => {
  //     const arr: any = [];
  //     const arr2: any = [];
  //     acceptedFiles?.forEach((file: File) => {
  //       if (file) {
  //         arr.push(file);
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //           arr2.push(reader.result);
  //         };
  //         reader.readAsDataURL(file);
  //       }
  //     });
  //     if (isPlantImages) {
  //       setPlantImages(arr);
  //       setUploadedImages(arr2);
  //     } else {
  //       // console.log("here is the video file", arr);

  //       setPlantVideo(arr[0]);
  //     }
  //   },
  //   [uploadedImages]
  // );
  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop: onDrop,
  // });
  // const [uploadedImages, setUploadedImages] = useState(
  //   plantImages?.length > 0 ? plantImages : []
  // );
  // const [imageUrls, setImageUrls] = useState([]);

  // useEffect(() => {
  //   setUploadedImages(plantImages?.length > 0 ? plantImages : []);
  // }, [plantImages]);

  // useEffect(() => {
  //   // Generate URLs for the uploaded images
  //   const urls = uploadedImages.map((file: any) => URL.createObjectURL(file));
  //   setImageUrls(urls);

  //   // Clean up URLs to avoid memory leaks
  //   return () => {
  //     urls.forEach((url: any) => URL.revokeObjectURL(url));
  //   };
  // }, [uploadedImages]);

  // const onDrop = useCallback(
  //   (acceptedFiles: any) => {
  //     if (isPlantImages) {
  //       setPlantImages(acceptedFiles);
  //       setUploadedImages(acceptedFiles);
  //     } else {
  //       setPlantVideo(acceptedFiles[0]);
  //     }
  //   },
  //   [isPlantImages, setPlantImages, setPlantVideo]
  // );

  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  // const [uploadedImages, setUploadedImages] = useState<(File | string)[]>([]);
  // console.log("🚀 ~ uploadedImages:", uploadedImages)
  // const [imageUrls, setImageUrls] = useState<string[]>(
  //   (isEdit || isEditDetail) && plantImages?.length > 0 ? plantImages : []
  // );

  // console.log("🚀 ~ imageUrls:", imageUrls)
  // useEffect(() => {
  //   // Set initial uploaded images from plantImages if any
  //   if ((isEdit || isEditDetail) && plantImages?.length > 0) {
  //     setUploadedImages(plantImages);
  //   }
  // }, [plantImages]);

  // useEffect(() => {
  //   // Generate URLs for the uploaded images
  //   const urls = uploadedImages
  //     .map((file) => {
  //       if (typeof file === "string") {
  //         return file;
  //       } else if (file instanceof File) {
  //         return URL.createObjectURL(file);
  //       }
  //       return null;
  //     })
  //     .filter((url): url is string => url !== null);
  //   setImageUrls(urls);

  //   // Clean up URLs to avoid memory leaks
  //   return () => {
  //     urls.forEach((url) => {
  //       if (url.startsWith("blob:")) {
  //         URL.revokeObjectURL(url);
  //       }
  //     });
  //   };
  // }, [uploadedImages]);

  // const onDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const newFiles = acceptedFiles.filter((file) => file instanceof File);

  //     if (isPlantImages) {
  //       // Append new files to the existing images
  //       setPlantImages((prev: any) => [...prev, ...newFiles]);
  //       setUploadedImages((prev) => [...prev, ...newFiles]);
  //     } else {
  //       setPlantVideo(newFiles[0]);
  //     }
  //   },
  //   [isPlantImages, setPlantImages, setPlantVideo]
  // );

  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  // const [selectedFiles, setSelectedFiles] = useState<any>([]);
  // const [uploadedImages, setUploadedImages] = useState<any>(
  //   (isEdit || isEditDetail) && plantImages?.length > 0 ? plantImages : []
  // );
  // const [videoFile, setVideoFile] = useState<File | null>(null);

  // // Update uploadedImages state when plantImages prop changes
  // useEffect(() => {
  //   setUploadedImages(
  //     (isEdit || isEditDetail) && plantImages?.length > 0 ? plantImages : []
  //   );
  // }, [plantImages]);

  // const onDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const newImages = acceptedFiles
  //       .filter((file) => file.type.startsWith("image/"))
  //       .map((file) => URL.createObjectURL(file));
  //     const newVideos = acceptedFiles
  //       .filter((file) => file.type.startsWith("video/"))
  //       .map((file) => URL.createObjectURL(file));

  //     if (isPlantImages) {
  //       setPlantImages(acceptedFiles);
  //       setUploadedImages((prevImages: any) => [...prevImages, ...newImages]);
  //     } else {
  //       const video = acceptedFiles.find((file) =>
  //         file.type.startsWith("video/")
  //       );
  //       if (video) {
  //         setPlantVideo(video);
  //         setVideoFile(video);
  //       }
  //     }

  //     setSelectedFiles(acceptedFiles);
  //   },
  //   [isPlantImages, setPlantImages, setPlantVideo]
  // );
  // console.table("MNK_", plantImages);
  // const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [uploadedImages, setUploadedImages] = useState<(string | File)[]>(
    (isEdit || isEditDetail) && plantImages?.length > 0 ? plantImages : []
  );
  const [imageUrls, setImageUrls] = useState<string[]>(
    (isEdit || isEditDetail) && plantImages?.length > 0 ? plantImages : []
  );
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const [imageUrlsList, setImageUrlsList] = useState<string[]>(
    (isEdit || isEditDetail) && plantImageList?.length > 0 ? plantImageList : []
  );
  useEffect(() => {
    if ((isEdit || isEditDetail) && plantImages?.length > 0) {
      setUploadedImages(plantImages);
    }
  }, [isEdit, isEditDetail, plantImages]);

  useEffect(() => {
    const urls = uploadedImages
      .map((file) => {
        if (typeof file === "string") {
          return file;
        } else if (file instanceof File) {
          return URL.createObjectURL(file);
        }
        return null;
      })
      .filter((url): url is string => url !== null);
    setImageUrls(urls);

    return () => {
      urls.forEach((url) => {
        if (url.startsWith("blob:")) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [uploadedImages]);

  useEffect(() => {
    if (videoFile) {
      const videoURL = URL.createObjectURL(videoFile);
      setVideoUrl(videoURL);

      return () => {
        URL.revokeObjectURL(videoURL);
      };
    }
  }, [videoFile]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const imageFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("image/")
      );
      const videoFiles = acceptedFiles.filter((file) =>
        file.type.startsWith("video/")
      );

      if (isPlantImages) {
        setPlantImages(imageFiles);
        const newImageUrls = imageFiles.map((file) =>
          URL.createObjectURL(file)
        );
        setUploadedImages((prevImages) => [...prevImages, ...newImageUrls]);
      } else if (videoFiles.length > 0) {
        const video = videoFiles[0];
        setPlantVideo(video);
        setVideoFile(video);
      }
    },
    [isPlantImages, setPlantImages, setPlantVideo]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleClearImages = () => {
    imageUrls.forEach((url) => URL.revokeObjectURL(url));
    setUploadedImages([]);
    setImageUrls([]);
    setPlantImages([]);
  };

  const handleClearVideo = () => {
    if (videoUrl?.startsWith("blob:")) {
      URL.revokeObjectURL(videoUrl);
    }
    setVideoFile(null);
    setVideoUrl(null);
    setPlantVideo(null);
  };

  return (
    // <div style={{ background: "#DAF4E1", padding: "0px 15px 10px 15px" }}>
    //   {(isPlantImages || isPlantVideos) && (
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "space-between",
    //       }}
    //     >
    //       <div
    //         style={{
    //           padding: "10px 0px",
    //           color: "#292929",
    //           fontSize: 24,
    //           fontFamily: "Alexandria",
    //           fontWeight: "700",
    //           wordWrap: "break-word",
    //         }}
    //       >
    //         {isPlantVideos ? "Plant Videos" : "Plant Images"}
    //       </div>
    //       <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
    //         <p
    //           style={{
    //             color: "#6E4C3E",
    //             fontSize: 20,
    //             fontFamily: "Alexandria",
    //             fontWeight: "700",
    //             wordWrap: "break-word",
    //           }}
    //         >
    //           Details
    //         </p>
    //         <p
    //           style={{
    //             color: "#535353",
    //             fontSize: 20,
    //             fontFamily: "Alexandria",
    //             fontWeight: "300",
    //             wordWrap: "break-word",
    //           }}
    //         >
    //           Logs
    //         </p>
    //       </div>
    //     </div>
    //   )}

    //   <div style={{ width: "100%", display: "flex" }}>
    //     <ul
    //       style={{
    //         listStyle: "none",
    //         display: "flex",
    //         flexWrap: "wrap",
    //         gap: "10px",
    //       }}
    //     >
    //       {imageUrls.map((imageUrl: string) => (
    //         <li key={imageUrl} style={{ position: "relative" }}>
    //           <img
    //             src={imageUrl}
    //             height={"200px"}
    //             width={"200px"}
    //             alt={`Uploaded Image`}
    //           />
    //           {!isEdit && !isEditDetail && (
    //             <button
    //               style={{
    //                 position: "absolute",
    //                 top: 0,
    //                 right: 0,
    //                 backgroundColor: "red",
    //                 width: "25px",
    //                 height: "25px",
    //                 borderRadius: "50%",
    //                 fontWeight: "bold",
    //                 color: "white",
    //                 border: "none",
    //                 cursor: "pointer",
    //               }}
    //               onClick={() => handleRemoveImage(imageUrl)}
    //             >
    //               X
    //             </button>
    //           )}
    //         </li>
    //       ))}
    //     </ul>
    //   </div>

    //   {videoUrl && (
    //     <div style={{ width: "430px", position: "relative" }}>
    //       <video width="400" controls>
    //         <source src={videoUrl} type={videoFile?.type} />
    //         Your browser does not support HTML video.
    //       </video>
    //       {!isEdit && !isEditDetail && (
    //         <button
    //           style={{
    //             position: "absolute",
    //             top: 0,
    //             right: 0,
    //             backgroundColor: "red",
    //             width: "25px",
    //             height: "25px",
    //             borderRadius: "50%",
    //             fontWeight: "bold",
    //             color: "white",
    //             border: "none",
    //             cursor: "pointer",
    //           }}
    //           onClick={handleRemoveVideo}
    //         >
    //           X
    //         </button>
    //       )}
    //     </div>
    //   )}

    //   <div
    //     style={{
    //       border: "1px #A5A5A5 dotted",
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       padding: "16px",
    //       borderRadius: 8,
    //     }}
    //     {...getRootProps()}
    //   >
    //     <input {...getInputProps()} />
    //     <FiUploadCloud size={50} color="#3E6E4B" />

    //     <p
    //       style={{
    //         color: "black",
    //         fontSize: 20,
    //         fontFamily: "Alexandria",
    //         fontWeight: "700",
    //         wordWrap: "break-word",
    //         marginTop: "20px",
    //       }}
    //     >
    //       Drag & drop files or{" "}
    //       <span
    //         style={{
    //           color: "#3E6E4B",
    //           fontSize: 20,
    //           fontFamily: "Alexandria",
    //           fontWeight: "700",
    //           wordWrap: "break-word",
    //         }}
    //       >
    //         Browse
    //       </span>
    //     </p>
    //     <p
    //       style={{
    //         textAlign: "center",
    //         color: "#535353",
    //         fontSize: 14,
    //         fontFamily: "Alexandria",
    //         fontWeight: "400",
    //         wordWrap: "break-word",
    //       }}
    //     >
    //       Supported formats: JPEG, PNG, GIF, MP4, PDF
    //     </p>
    //   </div>
    //   {/* <div
    //     style={{
    //       border: "1px #A5A5A5 dotted",
    //       display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       padding: "16px",
    //       borderRadius: 8,
    //     }}
    //   >
    //     <input
    //       type="file"
    //       id="file-input"
    //       data-testid="file-input"
    //       style={{ width: "100%", height: "100%" }}
    //       {...getInputProps()}
    //     />
    //     <label htmlFor="file-input"></label>
    //     <FiUploadCloud size={50} color="#3E6E4B" />

    //     <p
    //       style={{
    //         color: "black",
    //         fontSize: 20,
    //         fontFamily: "Alexandria",
    //         fontWeight: "700",
    //         wordWrap: "break-word",
    //         marginTop: "20px",
    //       }}
    //     >
    //       Drag & drop files or{" "}
    //       <span
    //         style={{
    //           color: "#3E6E4B",
    //           fontSize: 20,
    //           fontFamily: "Alexandria",
    //           fontWeight: "700",
    //           wordWrap: "break-word",
    //         }}
    //       >
    //         Browse
    //       </span>
    //     </p>
    //     <p
    //       style={{
    //         textAlign: "center",
    //         color: "#535353",
    //         fontSize: 14,
    //         fontFamily: "Alexandria",
    //         fontWeight: "400",
    //         wordWrap: "break-word",
    //       }}
    //     >
    //       Supported formats: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
    //     </p>
    //   </div> */}
    // </div>
    <div style={{ background: "#DAF4E1", padding: "0px 15px 10px 15px" }}>
      {(isPlantImages || isPlantVideos) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              padding: "10px 0px",
              color: "#292929",
              fontSize: 24,
              fontFamily: "Alexandria",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            {isPlantVideos ? "Plant Videos" : "Plant Images"}
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <p
              style={{
                color: "#6E4C3E",
                fontSize: 20,
                fontFamily: "Alexandria",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Details
            </p>
            <p
              style={{
                color: "#535353",
                fontSize: 20,
                fontFamily: "Alexandria",
                fontWeight: "300",
                wordWrap: "break-word",
              }}
            >
              Logs
            </p>
          </div>
        </div>
      )}
      {/* <div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
        {isPlantImages && !isEdit && !isEditDetail && imageUrls?.length > 0 && (
          <button
            onClick={handleClearImages}
            style={{
              backgroundColor: "red",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Remove All Images
          </button>
        )}
      </div> */}

      <div style={{ width: "100%", display: "flex" }}>
        <div className="plantcontainer">
          {imageUrls?.map((imageUrl: any, index: number) => (
            <div key={index} className="plantcard">
              <img
                src={imageUrl}
                title="Plant Image"
                alt="Plant Image"
              />
              <div className="plantcontent">
                {/* <div className="tplantitle">Image Title</div> */}
                {/* <div className="plantdescription">Description Description Description Description Description Description
                    Description Description Description Description Description Description</div> */}
                <button>
                  <FiTrash2
                    onClick={(e) => {
                      deletePlantImage(imageUrl?.id);
                    }}
                    color="#ff0000"
                  />
                </button>
              </div>
              <div className="planticons">
                {/* <button>
                  <FiTrash2 color="#ff0000"/>
                  </button> */}
                {/* <button>⬇️</button> */}
              </div>
            </div>
          ))}
        </div>

        <ul
          style={{
            listStyle: "none",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {/* {imageUrls.map((imageUrl: string, index: number) => (
            <li key={index} style={{ position: "relative" }}>
              
              <img
                src={imageUrl}
                height={"200px"}
                width={"200px"}
                alt={`Uploaded Image`}
              />
            </li>
          ))} */}
        </ul>
      </div>
      {/* <div style={{ display: "flex", justifyContent: "end", gap: "10px" }}>
        {isPlantVideos && !isEdit && !isEditDetail && videoUrl && (
          <button
            onClick={handleClearVideo}
            style={{
              backgroundColor: "red",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Remove Video
          </button>
        )}
      </div> */}
      {videoUrl && (
        <div style={{ width: "430px", position: "relative" }}>
          <video width="400" controls>
            <source src={videoUrl} type={videoFile?.type} />
            Your browser does not support HTML video.
          </video>
        </div>
      )}

      <div
        style={{
          border: "1px #A5A5A5 dotted",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "16px",
          borderRadius: 8,
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <FiUploadCloud size={50} color="#3E6E4B" />

        <p
          style={{
            color: "black",
            fontSize: 20,
            fontFamily: "Alexandria",
            fontWeight: "700",
            wordWrap: "break-word",
            marginTop: "20px",
          }}
        >
          Drag & drop files or{" "}
          <span
            style={{
              color: "#3E6E4B",
              fontSize: 20,
              fontFamily: "Alexandria",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            Browse
          </span>
        </p>
        <p
          style={{
            textAlign: "center",
            color: "#535353",
            fontSize: 14,
            fontFamily: "Alexandria",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          Supported formats: JPEG, PNG, GIF, MP4, PDF
        </p>
      </div>
    </div>
  );
};

export default DragFiles;
