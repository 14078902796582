import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "./style.css";
import { useEffect, useState } from "react";
import axios from "axios";

interface IPersonalDetails {
  isEdit?: boolean;
  employeeDetail?: any;
  setEmployeeDetail?: any;
}
const PersonalDetails: React.FC<IPersonalDetails> = ({
  isEdit,
  employeeDetail,
  setEmployeeDetail,
}) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const arr: any = [];
    axios
      .post(`https://appadmin.buyinpk.com/portalapis/Employees/getCountryList`)
      .then((res) => {
        // console.log(res);
        arr.push({
          id: 0,
          country_code: "",
          country_enName: "",
          country_arName: "",
          country_enNationality: "Select Nationality",
          country_arNationality: "",
        });
        res?.data?.map((d: any) => {
          arr.push({
            id: d?.id,
            country_code: d?.country_code,
            country_enName: d?.country_enName,
            country_arName: d?.country_arName,
            country_enNationality: d?.country_enNationality,
            country_arNationality: d?.country_arNationality,
          });
        });
        setCountries(arr);
      });
  }, []);
  return (
    <>
      <Row className="personal-details-row">
        <Col sm={6} md={6} lg={6}>
          <h3 className="detail-style">
            <FormattedMessage id="details" />
          </h3>
        </Col>
        <Col sm={6} md={6} lg={6} className="details-log-col">
          <h5 className="detail-log">
            <FormattedMessage id="details" />
          </h5>
          <p className="logg">
            <FormattedMessage id="logs" />
          </p>
        </Col>
      </Row>
      <Row className="personal-details-row2">
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="mother_name" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.mother_name}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  mother_name: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="nationality" />
            </p>
            <select
              disabled={isEdit}
              className="employee-form-input"
              size={1}
              value={employeeDetail?.country_id}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  country_id: e.target.value,
                }));
              }}
            >
              {countries?.map((country: any) => {
                return (
                  <option value={country.id}>
                    {country?.country_enNationality}
                  </option>
                );
              })}
            </select>
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="emirates_id" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.emiratesid}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  emiratesid: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="union_number" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.union_no}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  union_no: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="passport_number" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.passport_no}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  passport_no: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="mobile_number" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.home_mobile}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  home_mobile: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="office_number" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.office_no}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  office_no: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <div className="inputs-label-col">
            <p className="employee-title">
              <FormattedMessage id="email_address" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.home_email}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  home_email: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
        <Col sm={12}>
          <div style={{ padding: "10px" }}>
            <p className="employee-title">
              <FormattedMessage id="address" />
            </p>
            <input
              type="text"
              className="employee-form-input"
              disabled={isEdit}
              value={employeeDetail?.address}
              onChange={(e) => {
                setEmployeeDetail((prev: any) => ({
                  ...prev,
                  address: e.target.value,
                }));
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PersonalDetails;
