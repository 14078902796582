function SearchIcon() {
  return (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="44"
    //   height="44"
    //   fill="none"
    //   viewBox="0 0 44 44"
    // >
    //   <path
    //     fill="#FBF1ED"
    //     d="M0 8a8 8 0 018-8h28a8 8 0 018 8v28a8 8 0 01-8 8H8a8 8 0 01-8-8V8z"
    //   ></path>
    //   <path
    //     stroke="#6E4C3E"
    //     strokeLinecap="round"
    //     strokeWidth="2"
    //     d="M22 14.474c-4.97 0-9 4.147-9 9.263S17.03 33 22 33s9-4.147 9-9.263-4.03-9.263-9-9.263zm0 0V11m0 8.105v4.632M19.75 11h4.5m4.114 6.187l1.511-1.555"
    //   ></path>
    // </svg>
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 44 44" fill="none">
<path d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z" fill="#FBF1ED"/>
<path d="M30.2929 31.7071C30.6834 32.0976 31.3166 32.0976 31.7071 31.7071C32.0976 31.3166 32.0976 30.6834 31.7071 30.2929L30.2929 31.7071ZM26.8235 20.4118C26.8235 23.9529 23.9529 26.8235 20.4118 26.8235V28.8235C25.0575 28.8235 28.8235 25.0575 28.8235 20.4118H26.8235ZM20.4118 26.8235C16.8706 26.8235 14 23.9529 14 20.4118H12C12 25.0575 15.7661 28.8235 20.4118 28.8235V26.8235ZM14 20.4118C14 16.8706 16.8706 14 20.4118 14V12C15.7661 12 12 15.7661 12 20.4118H14ZM20.4118 14C23.9529 14 26.8235 16.8706 26.8235 20.4118H28.8235C28.8235 15.7661 25.0575 12 20.4118 12V14ZM24.9988 26.413L30.2929 31.7071L31.7071 30.2929L26.413 24.9988L24.9988 26.413Z" fill="#6E4C3E"/>
</svg>
  );
}

export default SearchIcon;
