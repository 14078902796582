import { useEffect, useState } from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import enMessages from "../src/languages/en.json";
import arMessages from "../src/languages/ar.json";
import { LanguageContext } from "./context/LanguageContext";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import EmployeeDetail from "./pages/EmployeeDetail";
import Employee from "./pages/Employee";
import Admin from "./pages/Admin";
import Head from "./pages/Head";
import ProtectedRoute from "./ProtectedRoutes";

function App() {
  const [locale, setLocale] = useState("en");
  const messages: any = {
    en: enMessages,
    ar: arMessages,
  };

  const hasToken = JSON.parse(sessionStorage.getItem("userDetails") + "");
  return (
    <div className="App">
      <IntlProvider locale={locale} messages={messages[locale]}>
        <LanguageContext.Provider value={{ locale, setLocale }}>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/" element={<ProtectedRoute />}>
              <Route
                path="/"
                element={<Admin />}
                // element={
                //   hasToken?.username == "admin" ? <Admin /> : <Employee />
                // }
              />
              <Route
                path="/employee-detail"
                element={<EmployeeDetail />}
              ></Route>
              <Route path="/employee" element={<Employee />}></Route>
              <Route path="/admin" element={<Admin />}></Route>
              <Route path="/head" element={<Head />}></Route>
            </Route>
            {/* <Route
              path="/"
              element={
                hasToken ? (
                  hasToken.username == "admin" ? (
                    <Admin />
                  ) : (
                    <Employee />
                  )
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/employee-detail"
              element={hasToken ? <EmployeeDetail /> : <Navigate to="/login" />}
            ></Route>
            <Route
              path="/employee"
              element={hasToken ? <Employee /> : <Navigate to="/login" />}
            ></Route>
             <Route
              path="/admin"
              element={hasToken ? <Admin /> : <Navigate to="/login" />}
            ></Route>
            <Route
              path="/head"
              element={hasToken ? <Head /> : <Navigate to="/login" />}
            ></Route> */}
          </Routes>
        </LanguageContext.Provider>
      </IntlProvider>
    </div>
  );
}

export default App;
