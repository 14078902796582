import React from 'react';
interface DashboardIconProps {
  width: number;
  height: number;
}
const UserIcon: React.FC<DashboardIconProps> = ({ width, height }) => {
  return (
    <div className="iconWrapper"> 
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 72 72" fill="none">
      <g clipPath="url(#clip0_186_3080)">
      <path d="M36 38.75C39.797 38.75 42.875 35.672 42.875 31.875C42.875 28.078 39.797 25 36 25C32.203 25 29.125 28.078 29.125 31.875C29.125 35.672 32.203 38.75 36 38.75ZM36 38.75C29.9249 38.75 25 42.4437 25 47M36 38.75C42.0751 38.75 47 42.4437 47 47" stroke="#9CC5A7" strokeWidth="2" strokeLinecap="round"/>
      </g>
      <defs>
      <clipPath id="clip0_186_3080">
      <rect width="24" height="24" fill="white" transform="translate(24 24)"/>
      </clipPath>
      </defs>
      </svg>
</div>
  );
}

export default UserIcon;
