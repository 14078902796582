import { Button, Dropdown, Input, MenuProps, Space, message } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./style.css";
import SettingSearchIcon from "../../assets/svg/SettingSearchIcon";
import LinesIcon from "../../assets/svg/LinesIcon";
import CopyCloseIcon from "../../assets/svg/CopyCloseIcon";
import DashboardIcon from "../../assets/svg/DashboardIcon";
import OpenCloseSIderIcon from "../../assets/svg/OpenCloseSider";
import Polygon from "../../assets/icons/Polygon.png";
import { DownOutlined, UserOutlined } from "@ant-design/icons";

interface ITableHeader {
  isSkelton?: boolean;
  setIsRightSidebar?: any;
  isRightSidebar?: boolean;
  setLeftWidth?: Dispatch<SetStateAction<string>>;
  width?: number;
  selectedLayout?: string;
  setSelectedLayout?: Dispatch<SetStateAction<string>>;
  setIsCopyClicked?: Dispatch<SetStateAction<boolean>>;
  isCopyClicked?: boolean;
  isAdmin: boolean;
  title: string;
  numberOfResults: number;
  handleMenuClick?: any;
  handleSearch?: any;
  selectedPage?: any;
}
const TableHeader: React.FC<ITableHeader> = ({
  isSkelton,
  width,
  setIsRightSidebar,
  isRightSidebar,
  setLeftWidth,
  selectedLayout,
  setSelectedLayout,
  isCopyClicked,
  setIsCopyClicked,
  isAdmin,
  title,
  numberOfResults,
  handleMenuClick,
  handleSearch,
  selectedPage,
}) => {

  useEffect(() => {
    if (isCopyClicked) {
      setSelectedLayout && setSelectedLayout("copy");
    } else {
      if (!isAdmin) {
        if (width) {
          if (width < 53) {
            setSelectedLayout && setSelectedLayout("card");
          } else {
            setSelectedLayout && setSelectedLayout("table");
          }
        }
      }
    }
  }, [width]);

  const items: MenuProps["items"] = [
    {
      label: "Employees",
      key: "1",
    },
    {
      label: "Requesters",
      key: "2",
    },
    {
      label: "Departments",
      key: "3",
    },
    {
      label: "Lists",
      key: "4",
    },
    {
      label: "Plants",
      key: "5",
    },
    {
      label: "Services",
      key: "6",
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <div
      className={
        isAdmin
          ? "table-header-container-admin"
          : width && width > 53
          ? "table-header-container"
          : "table-header-container-responsive"
      }
    >
      <div
        className={
          isAdmin
            ? "table-header-first-div-admin"
            : width && width > 35
            ? "table-header-first-div"
            : "table-header-first-div-responsive"
        }
        style={{
          gap: isSkelton ? "400px" : width && width > 35 ? "162px" : "",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {/* {isAdmin && <img src={Polygon} width={10} height={10} />} */}
            {selectedPage == "requests" ? (
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <span className="table-header-p">{title}</span>
                {/* <img src={Polygon} width={10} height={10} /> */}
              </div>
            ) : (
              isAdmin && (
                <Dropdown menu={menuProps}>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <span className="table-header-p">{title}</span>
                    <img src={Polygon} width={10} height={10} />
                  </div>
                </Dropdown>
              )
            )}
          </div>
          {isSkelton ? (
            <SkeletonTheme baseColor="#CDCDCD" height={20} width={77}>
              <div>
                <Skeleton />
              </div>
            </SkeletonTheme>
          ) : (
            <p style={{ color: "#535353", marginBottom: "2px" }}>
              {numberOfResults} Results
            </p>
          )}
        </div>
        {(localStorage.getItem("selectedSection") == "employee" ||
          localStorage.getItem("selectedSection") == "list" ||
          localStorage.getItem("selectedSection") == "plant" ||
          localStorage.getItem("selectedSection") == "service" ||
          localStorage.getItem("selectedSection") == "department" ||
          localStorage.getItem("selectedSection") == "requests" ||
          (localStorage.getItem("selectedSection") !== "5" &&
            localStorage.getItem("selectedSection") !== "6" &&
            localStorage.getItem("selectedSection") !== "7" &&
            localStorage.getItem("selectedSection") !== "8" &&
            localStorage.getItem("selectedSection") !== "9" &&
            localStorage.getItem("selectedSection") !== "10") ||
          !localStorage.getItem("selectedSection")) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: isAdmin ? (isSkelton ? "210px" : "270px") : "324px",
            }}
          >
            <Input
              size="small"
              className="RequestInputStyle"
              placeholder="Search here , example : land services .."
              prefix={<SettingSearchIcon width={24} height={24} />}
              width={212}
              height={48}
              onChange={handleSearch}
            />
            {/* <LinesIcon width={26} height={24} /> */}
          </div>
        )}
      </div>
      {isSkelton && !isAdmin ? (
        ""
      ) : (
        <div
          className={
            isAdmin
              ? "three-icons-div-admin"
              : width && width > 53
              ? "three-icons-div"
              : width && width > 35
              ? "three-icons-div-responsive"
              : "three-icons-div-responsive-2"
          }
        >
          {!isAdmin ||
            (isSkelton && (
              <div
                onClick={() => {
                  setIsCopyClicked && setIsCopyClicked((prev) => !prev);
                  setIsRightSidebar((prev: boolean) => !prev);
                  setLeftWidth && setLeftWidth(isRightSidebar ? "100%" : "65%");
                }}
                style={{
                  width: "44px",
                  height: "44px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:
                    selectedLayout == "copy" ? "#DAF4E1" : "white",
                  borderRadius: "8px",
                }}
              >
                <CopyCloseIcon width={24} height={24} />
              </div>
            ))}
          <div
            onClick={() => {
              setSelectedLayout && setSelectedLayout("card");
            }}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedLayout == "card" ? "#DAF4E1" : "white",
              borderRadius: "8px",
            }}
          >
            <DashboardIcon width={21} height={24} />
          </div>
          <div
            onClick={() => {
              setSelectedLayout && setSelectedLayout("table");
            }}
            style={{
              width: "44px",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: selectedLayout == "table" ? "#DAF4E1" : "white",
              borderRadius: "8px",
            }}
          >
            <OpenCloseSIderIcon width={21} height={21} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TableHeader;
