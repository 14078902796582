import { Col, Row } from "react-bootstrap";
import tumer from "../../assets/images/Tumer.svg";
import minus from "../../assets/images/minus.png";
import plant from "../../assets/images/plant.png";
import plus from "../../assets/images/plus.png";
import "./style.css";
import { useState } from "react";
const RequestedPlantsCard: React.FC<{ width: number }> = ({ width }) => {
  const [quantity, setQuantity] = useState(15)
  return (
    <div>
      <Row className="card_bg_color">
        <Col sm={2} className="plantRow">
          <div className="plantImage">
            <img src={plant} alt="img tumer" className="imgPlant" />
          </div>
        </Col>
        <Col sm={10} className="store">
          <p className="plantName">Name of the plant</p>
          <div className="storeDiv">
            <p className="plantQuantity">{120 - quantity}</p>
            <p className="plantText"> remained in the store</p>
          </div>
          <div className="plantButtons">
            <div className="QuantityButton"  onClick={() => setQuantity((prev) => prev - 1)}>
              <img src={minus} alt="minus" />
            </div>
            <p className="SelectedNumbers">{quantity}</p>
            <div className="QuantityButton" onClick={() => setQuantity((prev) => prev + 1)}>
              <img src={plus} alt="plus" />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RequestedPlantsCard;
