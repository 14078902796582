import React, { useState } from "react";
import "./style.css"; 

import { Loading } from "../../pages/Loading";
import AttentionIcon from "../../assets/svg/AttentionIcon";
import { Button } from "../Button";

interface ICustomModal {
  isOpen: boolean;
  onClose: any;
  children?: any;
  setIsLoading: any
}

const CustomModal: React.FC<ICustomModal> = ({ isOpen, onClose, children, setIsLoading }) => {
 
  const handleSendClick = async () => {
    setIsLoading(true);
    onClose();
    try {
      await new Promise((resolve) => setTimeout(resolve, 5000));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
    if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div
        className=""
        style={{
          backgroundColor: "white",
          width: "540px",
          height: "379px",
          borderRadius: "6px",
          paddingTop: "32px",
          paddingBottom: "32px",
          paddingLeft: "16px",
          paddingRight: "16px",
        }}
      >
        <div style={{display: 'flex', justifyContent: 'end', padding: '10px'}}>
          <button style={{position:'absolute', borderStyle: 'none', background: 'transparent'}} className="modalCloseBtn" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M13 1L1 13M13 13L1 1.00001" stroke="black" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          </button>
        </div>
        <div style={{textAlign:'center'}} >
<AttentionIcon width={96} height={96} />
        <p
          style={{
            color: "#E0BE08",
            fontSize: "24px",
            fontFamily: "Alexandria",
            fontWeight: "700",
            lineHeight: "36px",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          Attention !
        </p>
        </div>
        <p
          style={{
            color: "black",
            fontSize: "14px",
            fontFamily: "Alexandria",
            fontWeight: "400",
            lineHeight: "21px",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        >
          Are you sure you want to sen the request number 1234123 / person name
          - to approve? Keep in mind that once sent, you won't be able to make
          any further edits or undo the action.
        </p>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            className="modal-send-btn"
            title={"Send"}
            // onClick={() => <Loading />}
            onClick={handleSendClick}
          />
          <Button
            className="modal-cancel-btn"
            title={"Cancel"}
            onClick={onClose}
          />
        </div>
        
      </div>
    </div>
  );
};

export default CustomModal;
